import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Stack,
} from "@mui/material";
import { ResponsiveButtonGroup } from "..";
import {
  useAppDispatch,
  useAppSelector,
  closeDialog,
  openManagePaymentMethodDialog,
} from "../../appStore";

const DeletePaymentMethodDialog = () => {
  const dispatch = useAppDispatch();
  const openState = useAppSelector((state) => state.dialog.deletePaymentMethod);
  const close = () => {
    dispatch(closeDialog());
  };

  return (
    <Dialog open={openState} onClose={close}>
      <DialogTitle gutterBottom sx={{ mx: "54px", mb: 3 }}>
        Are you sure you want to delete this payment method?
      </DialogTitle>
      <DialogContent>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          sx={{
            button: {
              px: "64px",
            },
          }}
        >
          <ResponsiveButtonGroup
            reorder
            button1={
              <Button
                variant="outlined"
                size="large"
                className="custom-outlined"
              >
                Cancel
              </Button>
            }
            button2={
              <Button
                variant="contained"
                color="error"
                size="large"
                onClick={() => {
                  dispatch(openManagePaymentMethodDialog());
                }}
              >
                Delete
              </Button>
            }
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default DeletePaymentMethodDialog;
