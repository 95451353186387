import { Fragment } from "react";
import { Box, Typography, Stack } from "@mui/material";
import { useAppSelector, ReviewStepperSelector } from "../../../appStore";

/**
 * @returns Custom stepper component that can be used to show progress of contact review
 *
 */
const ReviewStepper = () => {
  const [numberOfSteps, value] = useAppSelector(ReviewStepperSelector);

  const makeSteps = (): JSX.Element[] => {
    let steps: JSX.Element[] = [];
    let reviewed = value;
    let pending = numberOfSteps - value;
    let keyCount = 0;

    // create reviewed markers
    while (reviewed--) {
      steps.push(
        reviewed ? (
          <Fragment key={keyCount++}>
            <span className="stepper-circle"></span>
            <span className="stepper-seperator"></span>
          </Fragment>
        ) : (
          <span key={keyCount++} className="stepper-circle"></span>
        )
      );
    }

    // if there is a pending marker create the first one with current contact styles
    if (pending) {
      pending === numberOfSteps
        ? steps.push(<span key={keyCount++} className="stepper-circle current"></span>)
        : steps.push(
            <Fragment key={keyCount++}>
              <span className="stepper-seperator"></span>
              <span className="stepper-circle current"></span>
            </Fragment>
          );
    }

    // create the rest of the markers
    while (pending--) {
      pending &&
        steps.push(
          <Fragment key={keyCount++}>
            <span className="stepper-seperator faded"></span>
            <span className="stepper-circle faded"></span>
          </Fragment>
        );
    }

    return steps;
  };

  // if there is only one contact in the digest show nothing
  return numberOfSteps !== 1 ? (
    <Box>
      <Typography fontSize={13} gutterBottom>
        {value} out of {numberOfSteps} reviewed 🔥
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignContent="center"
        alignItems="center"
        // dynamic width
        sx={{
          width: {
            xs: numberOfSteps * 20 + "px",
            sm: numberOfSteps * 30 + "px",
          },
        }}
        my={1}
      >
        {makeSteps()}
      </Stack>
    </Box>
  ) : null;
};

export default ReviewStepper;
