import { Typography, Stack, Box } from "@mui/material";
import ContactsReviewedSvg from "./contacts-reviewed.svg";
import ContactsUpdatedSvg from "./contacts-updated.svg";
import MarkedFavouritesSvg from "./marked-favourites.svg";
import ReviewStreakSvg from "./review-streak.svg";
import TagsAddedSvg from "./tags-added.svg";
import ContactsReviewedInactiveSvg from "./contacts-reviewed-inactive.svg";
import ContactsUpdatedInactiveSvg from "./contacts-updated-inactive.svg";
import MarkedFavouritesInactiveSvg from "./marked-favourites-inactive.svg";
import ReviewStreakInactiveSvg from "./review-streak-inactive.svg";
import TagsAddedInactiveSvg from "./tags-added-inactive.svg";

type BadgeProps = {
  icon: string;
  inactiveIcon: string;
  active: boolean;
  description: string;
};

export const Badge = (props: BadgeProps) => {
  return (
    <Stack
      sx={{
        width: 94,
        mb: 2,
      }}
    >
      <Box
        sx={{
          width: 94,
          height: 94,
          bgcolor: props.active ? "secondary.main" : "#e2e2ea",
          display: "grid",
          placeContent: "center",
          borderRadius: "4px",
        }}
        mb={1}
      >
        <img src={props.active ? props.icon : props.inactiveIcon} alt="" />
      </Box>
      <Typography
        sx={
          props.active
            ? {
                color: "primary.main",
                fontWeight: 600,
                lineHeight: "18px",
                fontSize: "12px",
                letterSpacing: "0.004em",
              }
            : {
                color: "#727272",
                letterSpacing: "0.004em",
                fontWeight: 500,
                lineHeight: "18px",
                fontSize: "12px",
              }
        }
      >
        {props.description}
      </Typography>
    </Stack>
  );
};

export const ContactsReviewedBadge = ({ active }: { active: boolean }) =>
  Badge({
    icon: ContactsReviewedSvg,
    inactiveIcon: ContactsReviewedInactiveSvg,
    active: active,
    description: "100 contacts reviewed",
  });

export const ContactsUpdatedBadge = ({ active }: { active: boolean }) =>
  Badge({
    icon: ContactsUpdatedSvg,
    inactiveIcon: ContactsUpdatedInactiveSvg,
    active: active,
    description: "100 contacts updated",
  });

export const MarkedFavouritesBadge = ({ active }: { active: boolean }) =>
  Badge({
    icon: MarkedFavouritesSvg,
    inactiveIcon: MarkedFavouritesInactiveSvg,
    active: active,
    description: "100 marked favourites",
  });

export const ReviewStreakBadge = ({ active }: { active: boolean }) =>
  Badge({
    icon: ReviewStreakSvg,
    inactiveIcon: ReviewStreakInactiveSvg,
    active: active,
    description: "30 day review streak",
  });

export const TagsAddedBadge = ({ active }: { active: boolean }) =>
  Badge({
    icon: TagsAddedSvg,
    inactiveIcon: TagsAddedInactiveSvg,
    active: active,
    description: "100 tags added",
  });
