import {
  Typography,
  Snackbar as MuiSnackbar,
} from "@mui/material";
type Props = {
  /**
   * The message to display
   */
  message: string;
  /**
   * function to be carried out on click
   */
  action?: React.ReactNode;
  open: boolean;
  bottomNav?: boolean;
  close: () => void;
};

const Snackbar = ({
  message,
  action,
  open,
  close,
  bottomNav = true,
}: Props) => {
  const onClose = () => {
    close();
  };
  return (
    <MuiSnackbar
      sx={{
        bgcolor: "#363636",
        bottom: bottomNav ? 110 : 16,
        ".MuiSnackbarContent-root": {
          width: action ? 484 : "fit-content",
        },
      }}
      className={"snackbar"}
      message={<Typography>{message}</Typography>}
      action={action}
      open={open}
      autoHideDuration={2000}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    />
  );
};

export default Snackbar;
