import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  animCount: false,
  animReason: "",
};

const mobileAnimSlice = createSlice({
  name: "mobileAnimation",
  initialState,
  reducers: {
    toggleAnimCount: (state, action: PayloadAction<[boolean, string]>) => {
      state.animCount = action?.payload[0];
      state.animReason = action?.payload[1];
    },
  },
});

export const { toggleAnimCount } = mobileAnimSlice.actions;

export default mobileAnimSlice.reducer;
