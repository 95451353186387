import { createSlice } from "@reduxjs/toolkit";
import { StatisticsType } from "../../../utils";
import { fetchStatistics, incrementMailingStat } from "./thunks";

const initialState: StatisticsType = {
  current_streak: 0,
  best_streak: 0,
  contacts_updated: 0,
  mailed: 0,
  contacts_reviewed: 0,
  reviewed_percentage: 0,
  contacts_updated_percentage: 0,
  favorite_contacts: 0,
  favorite_contacts_percentage: 0,
  labelled_contacts: 0,
  labelled_contacts_percentage: 0,
  streak_badge: false,
  contacts_reviewed_badge: false,
  contacts_updated_badge: false,
  contacts_tagged_badge: false,
  contacts_favorite_badge: false,
  review_streaks: [],
  missed: 0,
  partially_reviewed: 0,
  fully_reviewed: 0,
};

const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStatistics.fulfilled, (state, action) => {
        state = action.payload;
        return state;
      })
      .addCase(incrementMailingStat.fulfilled, (state, action) => {
        return state;
      });
  },
});

// export const {} = statisticsSlice.actions;

export default statisticsSlice.reducer;
