import { InfoCard, IconText } from "../..";
import { CheckRingRound } from "../../../assets/icons";
import { Box, Button, Typography, GridProps, useMediaQuery, Theme } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
  initializePayment,
  managePayment,
  selectIsSubscribed,
} from "../../../appStore";

/**
 *
 * @returns the yearly plan card - JSX element
 */

const YearlyPlanCard = ({
  outlined,
  ...props
}: GridProps & { outlined?: boolean }) => {
  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );

  const dispatch = useAppDispatch();
  const isSubscribed = useAppSelector(selectIsSubscribed);
  const activePlan = useAppSelector((state) =>
    state.payment.plan_details
  );
  const plan = useAppSelector((state) =>
    state.payment.plans.find((plan) => plan.interval === "year")
  );
  // const isyearlyPlan = useAppSelector(selectUserPlanId) === "yearly";
  const isyearlyPlan = activePlan?.id === plan?.id;
  const payment = useAppSelector((state) =>
    state.payment
  );

  return (
    <InfoCard
      header="Yearly"
      highlight={`${payment.trial_active ? 'Free 14 days, then' : ''} ${parseFloat(plan?.amount || "0")}$/y`}
      outlined={outlined}
      stackDirection={isMobile && payment.trial_active ? 'column' : 'row'}
      sx={{ width: { md: "548px", xl: "657px" } }}
      // allow custom sizing
      {...props}
    >
      <Box
        mb={4}
        sx={{
          "> *": {
            mb: 2,
          },
        }}
      >
        <IconText
          icon={<CheckRingRound />}
          text="Review your contacts easily through a daily email and app"
        />
        <IconText
          icon={<CheckRingRound />}
          text="Add tags, notes, and dates to organize and connect your contacts in new ways"
        />
        <IconText
          icon={<CheckRingRound />}
          text="We'll sync your updates automatically and continuously right to Google Contacts"
        />
        <IconText
          icon={<CheckRingRound />}
          text={`Only $${(parseFloat(plan?.amount || "0") / 52).toFixed(
            2
          )} a week`}
        />
        <IconText icon={<CheckRingRound />} text="You save 27%" />
      </Box>
      <Button
        variant="contained"
        fullWidth
        sx={{ mb: { xs: "22px", sm: 1 }, mt: { sm: 4.5 } }}
        onClick={() => {
          isSubscribed
            ? dispatch(managePayment())
            : dispatch(initializePayment(plan?.id || "yearly"));
        }}
      >
        {isSubscribed ? "Manage payment method" : "Start trial"}
      </Button>
      <Typography
        color="primary"
        textAlign="center"
        fontSize={13}
        fontWeight={400}
        sx={{
          cursor: isyearlyPlan ? "pointer" : "text",
        }}
        onClick={() => {
          isyearlyPlan && dispatch(managePayment());
        }}
      >
        {isyearlyPlan
          ? "Change to monthly"
          : `$${parseFloat(plan?.amount || "0")} charged annual until cancelled`}
      </Typography>
    </InfoCard>
  );
};

export default YearlyPlanCard;
