/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Theme, useMediaQuery } from "@mui/material";
import { ActionButtonGroup, SingleContactFullComponent } from "../../";
import {
  useAppDispatch,
  fetchActiveDigest,
  selectLoggedInStatus,
  useAppSelector,
  toggleAnimCount,
  selectAllContactsToReviewWithUrlID,
  toggleNoteInput,
  toggleDateInput,
  toggleTagInput,
  SelectActiveDigestId,
  openOlderDigestClickedDialog,
  SelectContactById,
  SelectDeleteContactLoading,
  setDeleteContactLoading,
  setEmailDigestId,
  SelectEmailDigestId,
  fetchStatistics,
  setCurrentContactId,
} from "../../../appStore";
import "../../../styles/contactgroup.css";
import { Fragment, useEffect, useRef, useState } from "react";
import { ContactType } from "../../../utils";
import MobileTransitionElement from "./MobileTransitionElement";

/*function generateMockContact() {
  return {
    id: Math.random(),
    email: "hello@world.com",
    first_name: "John",
    last_name: Math.random().toString().slice(2, 6),
    nickname: "Empty",
    department: "Empty",
    job_title: "Empty",
    image_url: "https://www.w3schools.com/howto/img_avatar.png",
    company_name: "acme",
    phone: "234234143",
    notes: "Empty",
    date_of_birth: "2021-09-01T00:00:00.000Z",
    last_updated: "123213",
    starred: false,
    link_to_contact: "google.com",
    status: "pending", //"pending" | "under_review" | "snoozed" | "reviewed" | "deleted";
    google_urls: [],
    google_events: [],
    labels: [],
  } as ContactType;
}*/

const ContactGroup = () => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(selectLoggedInStatus);
  const deleteLoading = useAppSelector(SelectDeleteContactLoading);
  // contact Id from url
  const [urlMetaData, setUrlMetaData] = useState<string[]>([""]);
  const originalURLArr = window.location.href.split("/");

  // (mobile) current contact index to
  // avoid waiting for the request to complete
  // and by doing that, improve UX on mobile when swiping
  const [currentIdx, setCurrentIdx] = useState<number>();

  // contacts to review
  const toReview = useAppSelector((state) =>
    selectAllContactsToReviewWithUrlID(state, urlMetaData[0])
  );
  const activeDigestId = useAppSelector(SelectActiveDigestId);
  const emailDigestId = useAppSelector(SelectEmailDigestId);
  const contactFromURL = useAppSelector((state) =>
    SelectContactById(state, +urlMetaData[0])
  );
  const [checkedContactFromURL, setCheckedContactFromURL] = useState(false);
  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );
  const contactComponentRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (
      typeof currentIdx === "undefined"
      || (toReview.length > 0 && currentIdx < 0)
      || (toReview.length <= currentIdx)
    ) {
      setCurrentIdx(toReview.length - 1);
    }
    if (toReview && currentIdx && toReview[currentIdx]) {
      dispatch(setCurrentContactId(toReview[currentIdx].id))
    }
  }, [toReview, currentIdx]);

  useEffect(() => {
    // console.log({deleteLoading})
    if (isMobile && deleteLoading) {
      // setCurrentIdx((prev) => (prev ? prev - 1 : 0));
      dispatch(setDeleteContactLoading());
    }
  }, [deleteLoading]);

  useEffect(() => {
    // check for an ID in the url
    let url = window.location.href.split("/");
    let meta = url[url.length - 1];

    meta && setUrlMetaData(meta.split("#"));

    // unset the animation count in case of a reload
    dispatch(toggleAnimCount([false, ""]));

    if (toReview) {
      contactComponentRef.current?.scrollIntoView({behavior: "smooth"})
    }
  }, []);
  
  useEffect(() => {
    if (originalURLArr[3] === "digest") {
      const digestId = +originalURLArr[4];
      if (activeDigestId && activeDigestId !== digestId) {
        dispatch(openOlderDigestClickedDialog());
      } else {
        dispatch(setEmailDigestId(digestId));
        isLoggedIn && dispatch(fetchActiveDigest());
        isLoggedIn && dispatch(fetchStatistics());
      // isLoggedIn && dispatch(fetchSpecificDigest(digestId));
      }
    } else {
      isLoggedIn && dispatch(fetchActiveDigest());
      isLoggedIn && dispatch(fetchStatistics());
    }
  }, [isLoggedIn, dispatch]);

  useEffect(() => {
    if (emailDigestId && activeDigestId && activeDigestId !== emailDigestId) {
      dispatch(openOlderDigestClickedDialog());
      dispatch(setEmailDigestId(null));
    }
  }, [emailDigestId, activeDigestId]);

  useEffect(() => {
    if (
      // urlMetadata is not undefined
      urlMetaData &&
      // the action key exists
      urlMetaData[1] &&
      // the id actually matches one of the contacts
      parseInt(urlMetaData[0]) === toReview[toReview.length - 1].id
    ) {
      urlMetaData[1] === "notes" && dispatch(toggleNoteInput(true));
      urlMetaData[1] === "dates" && dispatch(toggleDateInput(true));
      urlMetaData[1] === "tags" && dispatch(toggleTagInput(true));
    }
  }, [urlMetaData, toReview]);

  useEffect(() => {
    if (
      toReview.findIndex((item) => item.id === parseInt(urlMetaData[0])) === -1
    ) {
      window.history.replaceState({}, "", window.location.origin);
    }
  }, [toReview]);

  useEffect(() => {
    if (contactFromURL && contactFromURL.status === "reviewed" && !checkedContactFromURL) {
      dispatch(openOlderDigestClickedDialog());
      setCheckedContactFromURL(true);
      setUrlMetaData([""]);
    }
  }, [contactFromURL, checkedContactFromURL])

  return toReview.length ? (
    isMobile ? (
      // mobile version
      <Box className="mobile-contact-group">
        {toReview.map((item, index) => {
          if (index > (currentIdx ?? 0)) {
            return null;
          }

          if (index === currentIdx) {
            return (
              <div ref={contactComponentRef} key={index}>
                <MobileTransitionElement
                  onReview={() => {
                    //Make the next card interactive without
                    //waiting for the redux store to update
                    setCurrentIdx((prev) => (prev ? prev - 1 : 0));
                  }}
                  contact={item}
                >
                  <SingleContactFullComponent
                    onReview={() => {
                      //Make the next card interactive without
                      //waiting for the redux store to update
                      setCurrentIdx((prev) => (prev ? prev - 1 : 0));
                    }}
                    contact={item} 
                  />
                </MobileTransitionElement>
                <ActionButtonGroup contact={item} />
              </div>
            );
          } else {
            return (
              <Box className="action-card" key={index}>
                <SingleContactFullComponent contact={item} disabled />
              </Box>
            );
          }
        })}
      </Box>
    ) : (
      // desktop version
      <Box>
        <div ref={contactComponentRef}>
          <SingleContactFullComponent contact={toReview[toReview.length - 1]} />
        </div>

        {/* show the tip of the next contact if it exists */}
        {toReview.length >= 2 ? (
          <NextContactWithOverlay contact={toReview[toReview.length - 2]} />
        ) : null}
      </Box>
    )
  ) : (
    <></>
  );
};

export default ContactGroup;

const NextContactWithOverlay = ({ contact }: { contact: ContactType }) => {
  return (
    <div
      style={{
        height: 100,
        width: "100%",
        position: "absolute",
        left: 0,
        bottom: -40,
        overflow: "hidden",
      }}
    >
      {/* <div style={{ position: "relative" }}> */}
      <div
        style={{
          position: "absolute",
          left: 0,
          top: 20,
          backgroundColor: "red",
          opacity: 0.4,
          width: "100%",
          height: "100%",
          zIndex: 1,
          borderRadius: "18px",
          background: "rgba(232, 232, 232, 0.5)",
          backdropFilter: " blur(3px)",
        }}
      ></div>
      <SingleContactFullComponent contact={contact} disabled />
      {/* </div> */}
    </div>
  );
};