import Check from "@mui/icons-material/Check";

const CustomCheckbox = (
  props: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > & {
    label: React.ReactNode;
  }
) => {
  return (
    <label className="custom-checkbox-label">
      <input {...props} type="checkbox" {...props} />
      <span className="checkmark">
        <Check
          sx={{
            color: "white",
            fontSize: 12,
            stroke: "white",
            strokeWidth: 2,
          }}
        />
      </span>
      {typeof props.label === "string" ? (
        <span className="text">{props.label}</span>
      ) : (
        props.label
      )}
    </label>
  );
};

export default CustomCheckbox;
