import { forwardRef, useState } from "react";
import { SvgIconProps } from "@mui/material";
import StarBorderOutlined from "@mui/icons-material/StarBorderOutlined";
import FilledStar from "@mui/icons-material/Star";
import { LightTooltip } from "../";

type Props = SvgIconProps & {
  active: boolean;
  disabled?: boolean;
};

const Star = forwardRef<SVGSVGElement, Props>(
  ({ disabled, active, ...restofProps }, ref) => {
    const [hoverState, setHoverState] = useState<boolean>(false);

    return (
      <LightTooltip title={active ? "Unstar Contact" : "Star Contact"} arrow>
        <span
          aria-roledescription="button"
          style={{
            display: "grid",
            placeItems: "center",
            fontSize: "26px",
          }}
          onMouseOver={() => {
            setHoverState(true);
          }}
          onMouseLeave={() => {
            setHoverState(false);
          }}
        >
          {active || hoverState ? (
            <FilledStar
              ref={ref}
              className="filled-star-icon"
              tabIndex={disabled ? -1 : 1}
              fontSize="inherit"
              {...restofProps}
            />
          ) : (
            <StarBorderOutlined
              ref={ref}
              className="faded"
              tabIndex={disabled ? -1 : 1}
              fontSize="inherit"
              {...restofProps}
            />
          )}
        </span>
      </LightTooltip>
    );
  }
);

export default Star;
