import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
  Reducer,
  AnyAction,
} from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/es/storage";
import {
  dialogReducer,
  userReducer,
  settingsReducer,
  labelReducer,
  actionsReducer,
  actionInputReducer,
  statisticsReducer,
  errorReducer,
  paymentReducer,
  mobileAnimationReducer,
} from "./slices";
import undoable from "redux-undo";
// configuration for the redux persist instance
const persistConfig = {
  key: "root", // key of storage item
  version: 6,
  storage, // session storage is used
};

// combine all reducers from slices
const appReducer = combineReducers({
  user: userReducer,
  settings: settingsReducer,
  dialog: dialogReducer,
  actionInput: actionInputReducer,
  actions: undoable(actionsReducer),
  labels: labelReducer,
  statistics: statisticsReducer,
  errors: errorReducer,
  payment: paymentReducer,
  mobileAnimation: mobileAnimationReducer,
});

// implement the signout action here to clear out state and return an empty object to redux persist
const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === "user/signout") {
    // this applies to all keys defined in persistConfig(s)
    storage.removeItem("persist:root");
    window.location.href = window.location.origin;
    state = {} as RootState;
  }
  return appReducer(state, action);
};

// create persisted reducer : ensure to type it correctly - use result of combined reducers, can cause errors if wrongly typed
const persistedReducer: typeof appReducer = persistReducer(
  persistConfig,
  rootReducer
);

// create store
export const store = configureStore({
  reducer: persistedReducer,
  // disable serializable check for redux persist actions
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: process.env.NODE_ENV === "development",
});

// create persistor
export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
