import { Stack } from "@mui/material";
import { SingleStatistic } from "../..";
import SellOutlined from "@mui/icons-material/SellOutlined";
import ThumbUpAltOutlined from "@mui/icons-material/ThumbUpAltOutlined";
import HistorySharp from "@mui/icons-material/HistorySharp";
// import MailOutlineOutlined from "@mui/icons-material/MailOutlineOutlined";
import StarBorderOutlined from "@mui/icons-material/StarBorderOutlined";
import { StatisticsType } from "../../../utils";

const Statistics = ({ data }: { data: StatisticsType }) => {
  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      sx={{
        "> *": {
          mr: { sm: "20px" },
          mt: { xs: "10px", sm: "20px" },
        },
        "> *:nth-of-type(3)": {
          mr: { lg: 0 },
        },
      }}
    >
      <SingleStatistic
        title="Total contacts reviewed"
        icon={<ThumbUpAltOutlined />}
        figure={data.contacts_reviewed}
        progress={data.reviewed_percentage}
      />
      <SingleStatistic
        title="Total contacts updated"
        icon={<HistorySharp />}
        figure={data.contacts_updated}
        progress={data.contacts_updated_percentage}
      />
      <SingleStatistic
        title="Favourite contacts"
        icon={<StarBorderOutlined />}
        figure={data.favorite_contacts}
        progress={data.favorite_contacts_percentage}
      />
      <SingleStatistic
        title="Contacts with labels"
        icon={<SellOutlined />}
        figure={data.labelled_contacts}
        progress={data.labelled_contacts_percentage}
      />
      {/* <SingleStatistic
        title="Mailed directly / Mailing streak"
        icon={<MailOutlineOutlined />}
        figure={data.mailed}
        progress={data.mailed}
        percentage={false}
      /> */}
    </Stack>
  );
};

export default Statistics;
