import { Stack, Box, Typography } from "@mui/material";

type Props = {
  title: string;
  icon: JSX.Element;
  progress: number;
  figure: number;
  percentage?: boolean;
};

const SingleStatistic = ({ percentage = true, ...props }: Props) => {
  return (
    <Box
      sx={{
        width: { xs: 342, sm: 330 },
        height: 149,
        borderRadius: "8px",
        boxShadow: "0px 4px 18px 0px #B8B8B83D",
        bgcolor: "white",
        padding: { xs: "24px 24px 20px", sm: "20px" },
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography fontSize={13} sx={{ color: "#727272" }}>
          {props.title}
        </Typography>
        <Box
          sx={{
            width: 40,
            height: 40,
            borderRadius: "50%",
            bgcolor: "secondary.main",
            display: "grid",
            placeItems: "center",
            svg: {
              fontSize: "20px",
              color: "primary.main",
            },
          }}
        >
          {props.icon}
        </Box>
      </Stack>
      <Typography
        variant="h1"
        component="h4"
        fontWeight={600}
        gutterBottom
        fontSize={{ xs: 28, sm: 33 }}
      >
        {props.figure}
      </Typography>
      <Typography
        sx={{
          ".highlight": {
            fontSize: 20,
            fontWeight: 600,
            color: "primary.main",
          },
        }}
      >
        <span className="highlight">
          {percentage ? `${props.progress}% ` : props.progress}
        </span>
        <span>{percentage ? "of total" : null}</span>
      </Typography>
    </Box>
  );
};

export default SingleStatistic;
