import { MutableRefObject } from "react";

let startTime: number, previousTimeStamp: number;
let done: boolean = false;

type StepType = {
  timestamp: number;
  limit: number;
  speed: number;
  element: MutableRefObject<HTMLElement | null>;
  duration: number;
  negative?: boolean;
  startPoint?: number;
};

// https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame#examples

export default function animationStep({
  timestamp,
  limit,
  speed,
  element,
  duration,
  negative = false,
  startPoint,
}: StepType) {
  if (!element.current) return;
  if (startTime === undefined || startTime === 0) {
    startTime = timestamp;
  }
  
  const elapsed = timestamp - startTime;

  if (previousTimeStamp !== timestamp) {
    const count = Math.min(speed * elapsed, limit);
    element.current.style.transformOrigin = "50% 400%";
    element.current.style.transform = startPoint
      ? `rotate(${
          negative ? startPoint * 0.03 + -1 * count : startPoint * 0.03 + count
        }deg)`
      : `rotate(${negative ? -1 * count : count}deg)`;
    if (count === limit) {
      done = true;
    }
  }

  if (elapsed < duration) {
    previousTimeStamp = timestamp;
    if (!done) {
      window.requestAnimationFrame((ts) =>
        animationStep({
          timestamp: ts,
          element,
          limit,
          speed,
          duration,
          negative,
          startPoint,
        })
      );
    }
  }

  if (elapsed >= duration) {
    done = false;
    startTime = 0;
    previousTimeStamp = 0;
  }
}
