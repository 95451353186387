import { ReactNode } from "react";
import { Grid, Paper, Stack, Typography, GridProps } from "@mui/material";

type InfoCardProps = GridProps & {
  /**
   * Text to be displayed as the header of the custom card
   */
  header: string;
  /**
   * Text to be displayed on the top right in primary color
   */
  highlight: string;
  /**
   * Displays in the body of the card
   */
  children: ReactNode;
  /**
   * Determines if a primary colored border is to be shown. Defaults to false
   */
  outlined?: boolean;
  /**
   * Determines header stack direction. Defaults to row
   */
  stackDirection?: 'row' | 'column'
};

/**
 * Grid item, must be placed inside a Grid container component.
 * Takes up half the available grid width
 */
const InfoCard = ({
  header,
  highlight,
  children,
  outlined = false,
  stackDirection = 'row',
  ...props
}: InfoCardProps) => {
  return (
    <Grid item xs={12} md={6} {...props}>
      <Paper
        sx={{
          p: { xs: "30px 24px 47px", sm: "40px 30px 34px" },
          height: { sm: "100%" },
          border: outlined ? "2px solid var(--primary)" : "none",
          borderRadius: "8px",
        }}
      >
        <Stack
          direction={stackDirection}
          width="100%"
          justifyContent="space-between"
          alignItems={{ xs: "initial", sm: "center" }}
          mb={4}
          gap={1}
        >
          <Typography fontWeight={600} fontSize={23}>
            {header}
          </Typography>
          <Typography
            color="primary"
            fontWeight={600}
            fontSize={20}
          >
            {highlight}
          </Typography>
        </Stack>
        {children}
      </Paper>
    </Grid>
  );
};

export default InfoCard;
