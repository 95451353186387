import { useAppSelector } from "../../appStore";
import { ErrorLayout } from "..";

/**
 * @returns Dialog to display error when the toggle star action fails
 */
const ToggleStarError = () => {
  const openState = useAppSelector((state) => state.errors.toggleStarError);

  return (
    <ErrorLayout
      openState={openState}
      title="Can't add contact to favorites"
      description="Please refresh the page to try adding the contact to favorites again"
    />
  );
};

export default ToggleStarError;
