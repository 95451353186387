import { EventInputType, monthDifference } from "../../../utils";
import { Typography, Stack, Box } from "@mui/material";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import { useState, useEffect } from "react";

type Props = EventInputType & {
  onDelete: (index: number) => void;
  deleteKey: number;
};
/**
 * Shows the date, the event and how long ago the event was.
 *
 * Props to be added, date, Event
 */
const DateInfoGroup = (props: Props) => {
  const [date, setDate] = useState('');
  const [difference, setDifference] = useState<number>(0);
  const [yearsAgo, setYearsAgo] = useState<number>(0);
  let thisMonth = new Date().getMonth();
  const months = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];

  useEffect(() => {
    setDate(props.event_time.split('T')[0]);
  }, [props]);

  useEffect(() => {
    const monthFrom = (+date.split("-")[1]) - 1;
    const yearFrom = +date.split("-")[0];
    setDifference(monthDifference(monthFrom, yearFrom, new Date()));
  }, [date]);

  useEffect(() => {
    setYearsAgo(Math.ceil((difference - thisMonth) / 12));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [difference]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      mb={{ xs: 2, sm: "20px" }}
    >
      <Stack direction="row" spacing={2}>
        <div
          style={{
            backgroundColor: "white",
            width: "48px",
            height: "48px",
            borderRadius: "4px",
            boxShadow: " 0px 2px 3px 0px #9E9E9E4D",

            textAlign: "center",
            padding: "4px 12px",
            fontSize: "13px",
          }}
        >
          {/* display day and month */}
          {date.split("-")[2]} <br />{" "}
          {months[(+date.split("-")[1]) - 1]}
        </div>

        <Box>
          <Typography
            fontWeight={600}
            gutterBottom
            fontSize={{ xs: 12, sm: 16 }}
          >
            {props?.label}
          </Typography>
          <Typography className="faded" fontSize={13}>
            {((): string => {
              if (difference >= 0) {
                return difference
                  ? difference >= 12
                    ? `${yearsAgo} year${yearsAgo > 1 ? "s" : ""} ago`
                    : `${difference} month${difference > 1 ? "s" : ""} ago`
                  : "This month";
              }
              return difference
                ? difference <= -12
                  ? `${-1 * yearsAgo} year${yearsAgo < -1 ? "s" : ""} from now`
                  : `${-1 * difference} month${difference < -1 ? "s" : ""} from now`
                : "This month";
            })()}
          </Typography>
        </Box>
      </Stack>

      <DeleteOutline
        tabIndex={1}
        className="default-icon-with-state"
        fontSize="medium"
        onClick={() => {
          props.onDelete(props.deleteKey);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            props.onDelete(props.deleteKey);
          }
        }}
      />
    </Stack>
  );
};

export default DateInfoGroup;
