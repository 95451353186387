import { useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector, closeDialog } from "../../appStore";

/**
 *
 * @returns confirmation dialog for successful contact deletion
 */
const OlderDigestDialog = () => {
  const dispatch = useAppDispatch();
  const openState = useAppSelector((state) => state.dialog.olderDigestClicked);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (openState) {
      timer = setTimeout(() => {
        dispatch(closeDialog());
      }, 5000);
    }
    return () => {
      clearTimeout(timer);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openState]);

  const close = () => {
    dispatch(closeDialog());
  };

  return (
    <Dialog
      open={openState}
      sx={{ textAlign: "center" }}
      onClose={close}
      maxWidth="sm"
    >
      <DialogTitle>
        Cannot open contact
        {/* <CheckRingRound width={36} height={36} /> */}
      </DialogTitle>
      <DialogContent sx={{ px: "54px" }}>
        <Typography variant="h3" fontSize={18} fontWeight={400} mb={2}>
            The contact you tried to review is either from an older digest or was already reviewed.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default OlderDigestDialog;
