import React, { useEffect } from "react";
import {
    useAppSelector,
    selectUserData
} from "../../appStore";

const StripePricingTable = () => {
    const email = useAppSelector(selectUserData).email;

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://js.stripe.com/v3/pricing-table.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);
        
    return React.createElement("stripe-pricing-table", {
        "pricing-table-id": "prctbl_1NdwipAGEZvSIWniYfOpkUdl",
        "publishable-key": "pk_live_51LT3RdAGEZvSIWnilClb7CibD9JmyRzeG3B7FjpPkCJqRy7tPNU07wTKoDE3qicpWgWSPVtVWBUfoZqbqv2APQK000CEeto8Zj",
        "customer-email": email
    });
};

export default StripePricingTable;