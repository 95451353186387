import { Grid } from "@mui/material";

type Props = {
  // the buttons to display
  button1?: JSX.Element;
  button2?: JSX.Element;
  reorder?: boolean;
};
/***
 * This is not a dialog. Just a utility component for 2 grid buttons in the dialogs
 */

const ResponsiveButtonGroup = ({
  button1,
  button2,
  reorder = false,
}: Props) => {
  return (
    <Grid
      container
      direction="row"
      alignContent="center"
      justifyContent={!button2 || !button1 ? 'center' : undefined}
      spacing={{ sm: 2 }}
      sx={{
        maxWidth: 358,
        mx: "auto !important",
        button: {
          width: "100%",
          mb: "12px",
        },
      }}
    >
      {
        button1 &&
        <Grid item xs={12} sm={6} alignSelf="flex-end" order={reorder ? 2 : 1}
        >
          {button1}
        </Grid>
      }
      {
        button2 &&
        <Grid item xs={12} sm={6} order={reorder ? 1 : 2}
          style={{paddingLeft: button1 ? '' : '0'}}
        >
          {button2}
        </Grid>
      }
    </Grid>
  );
};

export default ResponsiveButtonGroup;
