import { createAsyncThunk } from "@reduxjs/toolkit";
import { AXIOS_CLIENT } from "../../";

export const fetchPlans = createAsyncThunk("payments/fetch_plans", async () => {
  let response = await AXIOS_CLIENT.get("payment/plan_details");

  return response.data;
});

export const fetchActivePlan = createAsyncThunk(
  "payments/fetch_active_plan",
  async () => {
    let response = await AXIOS_CLIENT.get("payment/details");

    return response.data;
  }
);

export const initializePayment = createAsyncThunk(
  "payments/fetch_initialize_payment",
  async (plan_id: string) => {
    let response = await AXIOS_CLIENT.get(
      `payment/initiate?plan_id=${plan_id}`
    );
    window.location.assign(response.data?.url);
  }
);

export const managePayment = createAsyncThunk(
  "payments/fetch_manage_payment",
  async () => {
    let response = await AXIOS_CLIENT.get("payment/manage");
    window.location.assign(response.data?.url);
  }
);

export const fetchPaymentStatus = createAsyncThunk(
  "payments/fetch_payment_status",
  async () => {
    try {
      let origin = window.location.origin;
      let rawSessionId = window.location.search;
      let session_id = rawSessionId.slice(rawSessionId.indexOf("=") + 1);
      let response = await AXIOS_CLIENT.post("payment/status", { session_id });

      if (response.data.status === "complete") {
      window.location.href = `${origin}/subscription-complete`;
      } else {
        window.location.href = `${origin}/subscription-error`;
      }
    } catch (err) {
      window.location.href = `${origin}/subscription-error`;
    }
  }
);
