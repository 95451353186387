import { Box, useMediaQuery, Theme } from "@mui/material";
import { ReactNode } from "react";
import { FooterLinks, Logo } from "../";

type Props = {
  /**
   * content to be displayed in the white part of the layout.
   *
   * !important Wrap the content in a parent container not a JSX fragment
   */
  leftContent: ReactNode;
  /**
   * Content to be displayed on the blue part of the layout
   *
   * !important - If content is more than one, wrap in a JSX Fragment
   */
  rightContent: ReactNode;
  /**
   * Content displayed at the top in the blue background for mobile devices
   */
  topContent?: ReactNode;
};
const AuthLayout = (props: Props) => {
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down(768));

  if (isMobile) {
    // mobile version
    return (
      <Box
        sx={{
          minHeight: "100vh",
          display: "grid",
          gridTemplateRows: "auto 1fr",
        }}
      >
        <Box
          className="blue-bg"
          sx={{
            p: "16px 8px 0",
            position: "relative",
            minHeight: "20vh",
            maxHeight: "60vh",
            textAlign: "center",
            mb: 7.5,
          }}
        >
          <Logo type="white" />
          <Box mb={-1} mt={2}>
            {props?.topContent}
          </Box>
        </Box>
        <Box
          className="white-bg"
          sx={{
            height: "100%",
            display: "grid",
            gridTemplateRows: "auto 1fr",
            mx: 3,
            textAlign: "center",
            button: {
              width: "100% !important",
            },
          }}
        >
          {props.leftContent}
          <Box sx={{ mt: "auto", mb: "30px" }}>
            <FooterLinks center />
          </Box>
        </Box>
      </Box>
    );
  }

  // desktop version
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        minHeight: "100vh",
      }}
    >
      <Box
        className="white-bg"
        sx={{
          display: "grid",
          gridTemplateRows: "auto 1fr auto",
          alignItems: "center",
          ">*": {
            mx: { xs: "10%", lg: "17%", xl: "20%" },
          },
        }}
      >
        <Box mt={{ xs: 3, xl: 5 }}>
          <Logo />
        </Box>
        {props.leftContent}
        <Box mb={{ xs: 2, xl: 5 }}>
          <FooterLinks />
        </Box>
      </Box>
      <Box
        className="blue-bg"
        sx={{
          display: "grid",
          placeItems: "center",
          alignContent: "center",
          ">*": {
            mx: "auto",
          },
        }}
      >
        {props.rightContent}
      </Box>
    </Box>
  );
};

export default AuthLayout;
