import Close from "@mui/icons-material/Close";
import { ClickAwayListener, Stack, Typography } from "@mui/material";
import { ActionContainer, ActionTrigger, Snackbar } from "../../";
import {
  addTagToContacts,
  deleteContactLabel,
  filterLabels,
  SelectContactById,
  selectTagInputVisisbility,
  toggleTagInput,
  useAppDispatch,
  useAppSelector,
  setContactTags,
  removeContactTags,
} from "../../../appStore";
import { ActionComponentProps, LabelObjectType, Option } from "../../../utils";
import CreateableSelect from "react-select/creatable";
import { useEffect, useState } from "react";
import { ActionCreators } from "redux-undo";

/**
 * Allows the editing, updating of tags and displays them with conditional logic
 */
const TagAction = ({ contact }: ActionComponentProps) => {
  const dispatch = useAppDispatch();
  // controls if the input should be shown
  const showInput = useAppSelector(selectTagInputVisisbility);
  const totalReviewed = useAppSelector((state) => state.statistics.contacts_reviewed);
  // current redux tag|label state
  const tagState = useAppSelector((state) =>
    SelectContactById(state, contact.id)
  )?.labels;
  // auto complete labels gotten from redux state
  const AutoCompleteLabels = useAppSelector((state) => state.labels).map(
    (tag) =>
      ({
        label: tag.name,
        value: tag.name,
      } as Option)
  );

  // for the snackbar and undo state
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [undoDelete, setUndoDelete] = useState<boolean>(false);
  const [IdToDelete, setIdToDelete] = useState<number>(-1);
  const [clickedId, setClickedId] = useState<number | null>(null)

  useEffect(() => {
    setTimeout(() => {
      if (clickedId !== null) {
        setClickedId(null)
      }
    }, 500);
  }, [clickedId])

  // delete a single tag
  const onDelete = (tag: LabelObjectType) => {
    dispatch(
      removeContactTags({
        id: contact.id,
        tag,
      })
    );
    // show the undo snackbar only if the input is not in focus
    if (!showInput) {
      dispatch(deleteContactLabel({ contactId: contact.id, labelId: tag.id }));
    } else {
      setIdToDelete(tag.id);
      setShowSnackbar(true);
    }
  };

  // get all user labels|tags to poulate datalist
  const handleFocus = () => {
    dispatch(filterLabels(""));
  };

  // close tag input and show tags outside of input on click outside the input
  const handleClickAway = () => {
    dispatch(toggleTagInput(false));
  };

  return (
    <ActionContainer
      id="tag-action-form"
      title="Tags"
      trigger={
        <ActionTrigger
          letter="T"
          description="Add Tag"
          onClick={() => {
            dispatch(toggleTagInput());
          }}
          onTouchEnd={(e) => dispatch(toggleTagInput())}
        />
      }
      description={
        !tagState?.length && !showInput
          ? (
              totalReviewed
                ? "You don't have any tags yet!"
                : "Turn your contacts into an efficient network with tags!"
            )
          : ""
      }
    >
      {/* display input if showInput state equals true */}
      {showInput ? (
        <ClickAwayListener
          onClickAway={handleClickAway}
          touchEvent={"onTouchEnd"}
        >
          <form
            onFocus={handleFocus}
            onSubmit={(e) => {
              e.preventDefault();
              // handleClickAway();
            }}
          >
            <CreateableSelect
              tabIndex={1}
              autoFocus
              isMulti
              isClearable={false}
              blurInputOnSelect={false}
              // show current tags inside the input
              defaultValue={tagState?.map((tag) => ({
                label: tag.name,
                value: tag.name,
              }))}
              onBlur={() => {
                dispatch(toggleTagInput(false));
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.stopPropagation();
                  dispatch(toggleTagInput(false));
                }
              }}
              onChange={(e, action) => {
                // add a tag
                if (
                  (action.action === "create-option" ||
                    action.action === "select-option") &&
                  // check if the tag is already existing
                  tagState?.findIndex(
                    (existing) => existing.name === action.option?.value
                  ) === -1
                ) {
                  // update redux store
                  dispatch(
                    setContactTags({
                      id: contact.id,
                      tag: action?.option as Option,
                    })
                  );
                  // send API request to add tag
                  dispatch(
                    addTagToContacts({
                      id: contact.id,
                      name: action.option?.value as string,
                    })
                  );
                }

                // delete a tag
                if (
                  action.action === "pop-value" ||
                  action.action === "remove-value"
                ) {
                  // get the tag from redux store
                  let tag = tagState?.find(
                    (existing) => existing.name === action.removedValue?.label
                  );

                  if (tag) {
                    // if the tag has a valid id delete via API request and remove from redux store
                    tag.id >= 0
                      ? onDelete(tag)
                      : // else remove only from redux store
                        dispatch(
                          removeContactTags({
                            id: contact.id,
                            tag,
                          })
                        );
                  }
                }
              }}
              options={AutoCompleteLabels}
              // custom styles
              styles={{
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isFocused ? "#e6f5ff" : "white",
                }),
                multiValueLabel: (base, state) => ({
                  ...base,
                  backgroundColor: "#e6f5ff",
                  color: "#55b1f5",
                }),
                multiValueRemove: (base, state) => ({
                  ...base,
                  backgroundColor: "#e6f5ff",
                  color: "#55b1f5",
                }),
              }}
            />
          </form>
        </ClickAwayListener>
      ) : null}

      {/* tags to show when the input is toggled off */}
      <Stack
        direction="row"
        flexWrap="wrap"
        sx={{
          "> *": {
            margin: "8px 8px 0 0 !important",
            padding: "8px, 12px, 8px, 16px",
          },
        }}
      >
        {tagState?.map((tag, index) => (
            <div
              key={index}
              id={`tag${tag.id}`}
              style={{
                fontFamily: "Poppins",
                display: clickedId === tag.id ? "none" : "inline-flex",
                justifyContent: "center",
                textDecoration: "none",
                border: 0,
                verticalAlign: "middle",
                boxSizing: "border-box",
                fontSize: "13px",
                borderRadius: "4px",
                padding: "8px",
                height: "44px",
                fontWeight: 500,
                cursor: "pointer",
                color: "#55B1F5",
                backgroundColor: "#E6F5FF"
              }}
            >
              <span
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  paddingLeft: "12px",
                  paddingRight: "12px",
                  paddingTop: "4px",
                  whiteSpace: "nowrap",
                }}
              >{tag.name}</span>
              <Close
                tabIndex={-1}
                onClick={(e) => {
                  setClickedId(tag.id)
                  onDelete(tag);
                }}
              />
            </div>
            /*<Chip
              key={index}
              color="secondary"
              label={tag.name}
              onDelete={() => {
                onDelete(tag);
              }}
              deleteIcon={
                <Close
                  tabIndex={-1}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      onDelete(tag);
                    }
                  }}
                />
              }
              sx={{
                svg: {
                  color: "inherit !important",
                },
              }}
            />*/
          ))}

        {/* <Chip color="secondary"/> */}
      </Stack>

      {/* snackbar */}
      <Snackbar
        open={showSnackbar}
        message="Tag deleted"
        action={
          <Typography
            role="button"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setUndoDelete(true);
              setShowSnackbar(false);
              dispatch(ActionCreators.undo());
            }}
          >
            undo
          </Typography>
        }
        close={() => {
          setShowSnackbar(false);
          if (!undoDelete) {
            dispatch(
              deleteContactLabel({ contactId: contact.id, labelId: IdToDelete })
            );

            setTimeout(() => {
              setUndoDelete(false);
            }, 2000);
          }
        }}
        bottomNav={false}
      />
    </ActionContainer>
  );
};

export default TagAction;
