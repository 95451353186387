import { useAppSelector } from "../../appStore";
import { ErrorLayout } from "..";

/**
 * @returns Dialog to display error when the delete contact event action fails
 */
const DeleteContactEventError = () => {
  const openState = useAppSelector(
    (state) => state.errors.deleteContactEventError
  );

  return (
    <ErrorLayout
      openState={openState}
      title="Can’t delete contact event"
      description="Please refresh the page to try deleting the event again"
    />
  );
};

export default DeleteContactEventError;
