import { useAppSelector } from "../../appStore";
import { ErrorLayout } from "..";

/**
 * @returns Dialog to display error when the review actionfails
 */
const ReviewError = () => {
  const openState = useAppSelector((state) => state.errors.reviewError);

  return (
    <ErrorLayout
      openState={openState}
      title="Can’t review contact"
      description="Please refresh the page to try finishing the review again"
    />
  );
};

export default ReviewError;
