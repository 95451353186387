import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  useMediaQuery,
  Theme,
} from "@mui/material";
import { ResponsiveButtonGroup } from "..";
import {
  useAppDispatch,
  useAppSelector,
  closeDialog,
  deleteContact,
  SelectDeleteContactLoading,
} from "../../appStore";

const DeleteContactDialog = ({ id }: { id: number }) => {
  const dispatch = useAppDispatch();
  const openState = useAppSelector((state) => state.dialog.deleteContact);
  const deleteLoading = useAppSelector(SelectDeleteContactLoading);
  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );
  const close = () => {
    !isMobile && dispatch(closeDialog());
  };

  const deleteContactAndClose = () => {
    dispatch(closeDialog());
    dispatch(deleteContact(id));
  };

  return (
    <Dialog
      open={openState}
      onClose={close}
      sx={{
        ".MuiDialog-paper": {
          maxWidth: 672,
        },
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          deleteContactAndClose();
        }
      }}
    >
      <DialogTitle gutterBottom sx={{ px: "54px", mb: 3 }}>
        Are you sure you want to delete this contact?
      </DialogTitle>
      <DialogContent>
        <ResponsiveButtonGroup
          reorder
          button1={
            <Button
              variant="outlined"
              size="large"
              className="custom-outlined"
              onClick={() => dispatch(closeDialog())}
            >
              Cancel
            </Button>
          }
          button2={
            <Button
              variant="contained"
              color="error"
              size="large"
              onClick={deleteContactAndClose}
              disabled={deleteLoading}
            >
              Delete
            </Button>
          }
        />
        <Typography
          fontSize={8}
          fontWeight={400}
          lineHeight="12px"
          mt={2}
          textAlign="center"
        >
          Deleted contacts may be available in Google Contacts Trash for up to
          30 days
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteContactDialog;
