import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import HistorySharp from "@mui/icons-material/HistorySharp";
import ThumbUpAltOutlined from "@mui/icons-material/ThumbUpAltOutlined";
import { ReviewStepper } from "../../";
import { Box, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import {
  SelectAllContacts,
  toggleAnimCount,
  useAppDispatch,
  useAppSelector,
} from "../../../appStore";

const ActionTopNav = () => {
  const dispatch = useAppDispatch();
  const contactsLength = useAppSelector(SelectAllContacts).length;
  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );
  const currentStreak = useAppSelector(
    (state) => state.statistics.current_streak
  );
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      flexWrap="wrap"
      alignItems={{ xs: "flex-end", sm: "center" }}
      mt={{ xs: 2, sm: 7 }}
      sx={{
        mx: { xs: "16px", sm: "1rem", lg: "inherit" },
        mb: "10px !important",
      }}
    >
      {isMobile ? (
        // mobile top snooze
        <Box
          className="snooze-top-mobile-container"
          onClick={() => {
            dispatch(toggleAnimCount([true, "snooze"]));
          }}
        >
          <ArrowBack />
          <HistorySharp />
        </Box>
      ) : (
        // desktop streak info
        <Box>
          <Typography variant="h2" fontSize={23} mb={0.5}>
            Review your contacts
          </Typography>
          {currentStreak ? (
            <Typography>
              You’re in a {currentStreak} digests streak, keep it going!
            </Typography>
          ) : null}
        </Box>
      )}
      {/* review stepper both mobile and desktop */}
      <Box
        mb={{ xs: "7px", sm: 0 }}
        sx={{
          // reorder stepper position if the contacts to review are more than 10
          order: contactsLength > 10 ? 3 : 0,
          width: contactsLength > 10 ? "100%" : "fit-content",

          // reorder the stepper for contacts less than 10 when the screen is too small
          "@media (max-width: 320px)": {
            order: 3,
          },
        }}
      >
        <ReviewStepper />
      </Box>
      {isMobile ? (
        // mobile top review
        <Box
          className="review-top-mobile-container"
          onClick={() => {
            dispatch(toggleAnimCount([true, "review"]));
          }}
        >
          <ThumbUpAltOutlined />
          <ArrowForward />
        </Box>
      ) : null}
    </Stack>
  );
};

export default ActionTopNav;
