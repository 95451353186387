import EditOutlined from "@mui/icons-material/EditOutlined";
import LinkedIn from "@mui/icons-material/LinkedIn";
import {
  Avatar,
  Box,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ActionButton, ActionTrigger, StarIcon } from "../../";
import {
  incrementMailingStat,
  selectLinkedInUrl,
  setContactStar,
  toggleContactStarState,
  useAppDispatch,
  useAppSelector,
} from "../../../appStore";
import { ActionComponentProps } from "../../../utils";
import defaultImage from "../../../assets/images/no-contact-image.svg";

/**
 * Displays the basic information of a contact
 */
const ContactBasicInfo = ({ contact, disabled }: ActionComponentProps) => {
  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );
  const dispatch = useAppDispatch();
  const linkedInUrl = useAppSelector((state) =>
    selectLinkedInUrl(state, contact.id)
  );

  const sendMail = () => {
    if (contact.email) {
      dispatch(incrementMailingStat());
      window.open(`mailto:${contact.email}`);
    }
  };

  const toggleStar = () => {
    dispatch(setContactStar(contact.id));
    dispatch(toggleContactStarState(contact.id));
  };

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction={{ xs: "column", sm: "row" }} spacing={2} style={{width: "100%"}}>
        <Avatar
          src={contact.image_url || defaultImage}
          sx={{
            width: { xs: 78, sm: 132 },
            height: { xs: 78, sm: 132 },
            borderRadius: { xs: "12px", sm: "4px" },
          }}
          variant="rounded"
          imgProps={{
            referrerPolicy: "no-referrer",
          }}
        />
        <Box
          sx={{
            a: {
              textDecoration: "none",
            },
          }}
        >
          <Stack direction="row" alignItems="center" spacing={2} mb="10px">
            <Typography
              variant="h2"
              sx={{
                width: { xs: "320px", sm: "100%" },
                overflow: { xs: "hidden" },
                textOverflow: { xs: "ellipsis" }
              }}
            >
              <span>{contact.first_name ? contact.first_name : ""} </span>
              <span>{contact.last_name ? contact.last_name : ""}</span>
              {!contact.first_name && !contact.last_name && null}
            </Typography>

            {/*  */}

            {!isMobile ? (
              <>
                {linkedInUrl ? (
                  <Box alignItems="center" display="flex">
                    <a
                      href={linkedInUrl}
                      className="place-center"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <LinkedIn
                        tabIndex={disabled ? -1 : 1}
                        htmlColor="#928F98"
                      />
                    </a>
                  </Box>
                ) : null}
                <StarIcon
                  disabled={disabled}
                  active={contact.starred}
                  onClick={toggleStar}
                  onTouchEnd={toggleStar}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.stopPropagation();
                      toggleStar();
                    }
                    if (e.key === "Tab" && e.shiftKey) {
                      e.preventDefault();
                      //return tab to review button
                      let reviewBtn = document.querySelector("#reviewBtn") as HTMLElement;
                      reviewBtn?.focus();
                    }
                  }}
                  tabIndex={disabled ? -1 : 1}
                  id="starIcon"
                />
              </>
            ) : null}

            {/*  */}
          </Stack>

          <Box
            sx={{
              "> *": {
                lineHeight: "20px",
                fontSize: "13px",
              },
            }}
          >
            {/* <Typography
              sx={{
                fontSize: 13,
                fontWeight: 500,
                letterSpacing: ".25px",
                lineHeight: "20px",
                color: isMobile ? "#5B5B5B" : "var(--light-text)",
                marginBottom: "10px",
              }}
            >
              {contact.nickname ? `${contact.nickname}` : null}
            </Typography> */}
            <Typography
              sx={{
                fontSize: 13,
                fontWeight: 500,
                letterSpacing: ".25px",
                lineHeight: "20px",
                marginBottom: "10px",
                color: "var(--light-text)",
              }}
              mb="10px"
            >
              <span>{contact.job_title ? contact.job_title : null}</span>
              <span>
                {contact.company_name
                  ? (contact.job_title ? ", " : '') + contact.company_name
                  : null}
              </span>
            </Typography>
            {contact.email ? (
              <Box mb={{ xs: "10px", sm: "6px" }}>
                <a
                  href={`mailto:${contact.email}`}
                  tabIndex={disabled ? -1 : 1}
                  onClick={() => {
                    dispatch(incrementMailingStat());
                  }}
                  onKeyDown={(e) => { 
                    if (e.key === "Enter") {
                      e.stopPropagation();
                    }
                  }}
                >
                  {contact.email}
                </a>
              </Box>
            ) : null}

            {contact.phone ? (
              <Box>
                <a
                  href={`tel:${contact.phone}`}
                  tabIndex={disabled ? -1 : 1}
                  onKeyDown={(e) => { 
                    if (e.key === "Enter") {
                      e.stopPropagation();
                    }
                  }}
                >
                  {contact.phone}
                </a>
              </Box>
            ) : null}
          </Box>

          {/* mobile social icons  */}
          {isMobile && linkedInUrl ? (
            <Box alignItems="center" display="flex" mt="12px">
              <a
                href={linkedInUrl}
                target="_blank"
                rel="noreferrer"
                onKeyDown={(e) => { 
                  if (e.key === "Enter") {
                    e.stopPropagation();
                  }
                }}
              >
                <LinkedIn tabIndex={disabled ? -1 : 1} htmlColor="#928F98" />
              </a>
            </Box>
          ) : null}
        </Box>
      </Stack>

      {/* settings */}

      <Stack
        alignItems="flex-end"
        justifyContent="space-between"
        sx={{ height: 132 }}
      >
        {/*  */}

        {!isMobile ? (
          <>
            <a
              href={contact.link_to_contact}
              target="_blank"
              className="place-center"
              style={{ textDecoration: "none" }}
              rel="noreferrer"
              tabIndex={-1}
            >
              <ActionButton
                disabled={disabled}
                sx={{ mr: -1 }}
                letter="e"
                tooltip="This action will open the contact in Google Contacts"
                startIcon={<EditOutlined />}
              />
            </a>
            {
              contact.email &&
                <ActionTrigger
                  disabled={disabled}
                  letter="M"
                  description="Send Mail"
                  onClick={() => sendMail()}
                />
            }
            {" "}
          </>
        ) : (
          <StarIcon
            disabled={disabled}
            active={contact.starred}
            onClick={toggleStar}
            onTouchEnd={toggleStar}
            onKeyDown={(e) => {
              if (e.key === "Enter") toggleStar();
            }}
          />
        )}

        {/*  */}
      </Stack>
    </Stack>
  );
};

export default ContactBasicInfo;
