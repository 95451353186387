import { Button, Avatar, ButtonProps } from "@mui/material";
import { LightTooltip } from "../../";
/**
 * extends the MUI button component and includes a letter prop that displays a single Alphabet beside the start Icon
 * @param letter
 *
 */
const ActionButton = (
  props: ButtonProps & {
    /**
     * Single alphabet to be rendered beside the start Icon in white background
     */
    letter: string;
    /**
     * Tooltip title defaults to an empty string
     */
    tooltip?: React.ReactNode;
  }
) => {
  return (
    <LightTooltip
      title={props.tooltip ? props.tooltip : ""}
      arrow
      sx={{
        maxWidth: "163px",
        ".MuiTooltip-tooltip": { px: 1.5, py: 1.75 },
      }}
    >
      <Button
        {...props}
        sx={{
          ...props.sx,
          padding: "12px 12px 12px 16px !important",

          svg: {
            color: "white",
          },
        }}
        variant="contained"
        tabIndex={1}
      >
        <Avatar
          variant="rounded"
          sx={{
            bgcolor: "white",
            width: 24,
            height: 24,
            ml: 0.5,
            fontSize: 12,
            color: props.color ? `${props.color}.main` : "primary.main",
          }}
        >
          {props?.letter.toUpperCase().charAt(0)}
        </Avatar>
      </Button>
    </LightTooltip>
  );
};

export default ActionButton;
