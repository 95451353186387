import { Typography, Collapse, Box, useMediaQuery, Theme } from "@mui/material";
import ToggleExpandButton from "./ToggleExpandButton";
import React, { useState, useEffect } from "react";
import { EventObjectType } from "../../../utils";
import DateInfoGroup from "./DateInfoGroup";
import { Snackbar } from "../../";
import {
  deleteContactEvents,
  // removeContactEvents,
  useAppDispatch,
} from "../../../appStore";
// import { ActionCreators } from "redux-undo";

interface sorted {
  [index: string]: EventObjectType[];
}
const DateList = ({
  data,
  contactId,
}: {
  data: EventObjectType[];
  contactId: number;
}) => {
  const dispatch = useAppDispatch();
  const [collapseState, setCollapseState] = useState<boolean>(false);
  const [sortedData, setSortedData] = useState<sorted>({});
  const [years, setYears] = useState<string[]>([]);
  const [showPreview, setShowPreview] = useState<boolean>(false);

  // for the snackbar and undo state
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [undoDelete, setUndoDelete] = useState<boolean>(false);
  const [deletedEventCount, setDeletedEventCount] = useState(0);
  const [IdToDelete, setIdToDelete] = useState<number>(-1);
  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );

  // create an array of unique dates
  useEffect(() => {
    if (deletedEventCount === 0) {
      setYears(
        data
          .map((item) => item.event_time.substring(0, 4))
          .filter((self, index, arr) => arr.indexOf(self) === index)
          .sort((a, b) => b.localeCompare(a))
      );
    } else {
      setDeletedEventCount((prev) => prev - 1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    /**
     * object to hold the event data sorted by year in key (year) value (array of events) pairs
     */
    let obj: sorted = {};

    //   map through the dates and assign the obj value array for each year
    years.forEach((year) => {
      obj[year] = data.filter(
        (item) => item.event_time.substring(0, 4) === year
      );
    });

    // save in state
    setSortedData(obj);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [years]);

  useEffect(() => {
    // update showPreview value
    if (sortedData[years[0]]?.length > 2 || data.length > 2) {
      setShowPreview(true);
      collapseState && setCollapseState(false);
    } else {
      setShowPreview(false);
      setCollapseState(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // toggle the collapse state of the see more section
  const toggleCollapse = () => {
    setCollapseState((prev) => !prev);
    setShowPreview((prev) => !prev);
  };
  // delete an event
  const onDelete = (eventId: number, dataKey: string) => {
    // dispatch(removeContactEvents({ id: contactId, eventId: eventId }));
    const newSortedData = {...sortedData};
    newSortedData[dataKey] = newSortedData[dataKey].filter(evt => evt.id !== eventId);

    setSortedData(newSortedData);
    // console.log({eventId})
    setIdToDelete(eventId);
    setDeletedEventCount(deletedEventCount + 1);
    setShowSnackbar(true);
  };
  return (
    <>
      {
        //   show the preview of the dates if they are more than 4 or from more than one year
        showPreview ? (
          <>
            <Typography fontSize={12} className="faded" mb={0.25}>
              {years[0]}
            </Typography>
            {sortedData[years[0]]?.slice(0, 2).map((event, dateIndex) => (
              <DateInfoGroup
                event_time={event.event_time}
                onDelete={() => {
                  onDelete(event.id, years[0]);
                }}
                label={event?.label}
                key={dateIndex}
                deleteKey={dateIndex}
              />
            ))}
          </>
        ) : null
      }

      {
        // show the "see more" button when the collapse state is set to false and the first year has more than 4 events
        !collapseState && showPreview && data.length > 2 ? (
          <ToggleExpandButton
            collapse={collapseState}
            toggleCollapse={toggleCollapse}
          />
        ) : null
      }

      {/* Show all the events when the collapse state is set to true */}
      <Collapse
        in={collapseState}
        timeout={{
          enter: sortedData[years[0]]?.length > 2 || data.length > 2 ? 700 : 0,
        }}
        unmountOnExit
        mountOnEnter
      >
        {Object.keys(sortedData) // convert sorted object to an array
          .sort((a, b) => b.localeCompare(a)) // sort by date => latest first
          //map through the array to display  dates
          .map((item, index) => (
            <Box key={index}>
              <Typography fontSize={12} className="faded" mb={0.25}>
                {item}
              </Typography>
              {sortedData[item].map((event, dateIndex) => (
                <DateInfoGroup
                  event_time={event.event_time}
                  onDelete={() => {
                    onDelete(event.id, item);
                  }}
                  label={event?.label}
                  key={dateIndex}
                  deleteKey={dateIndex}
                />
              ))}
            </Box>
          ))}
        {data.length > 2 ? (
          <ToggleExpandButton
            collapse={collapseState}
            toggleCollapse={toggleCollapse}
          />
        ) : null}
      </Collapse>

      {/* snackbar */}
      <Snackbar
        open={showSnackbar}
        message="Date deleted"
        
        action={
          <Typography
            role="button"
            sx={{ cursor: "pointer", zIndex: isMobile ? 10 : 1 }}
            onClick={() => {
              setUndoDelete(true);
              setShowSnackbar(false);
              setDeletedEventCount(deletedEventCount - 1);
              setYears([...years]);
              // dispatch(ActionCreators.undo());
            }}
          >
            undo
          </Typography>
        }
        close={() => {
          setShowSnackbar(false);
          if (!undoDelete) {
            dispatch(
              deleteContactEvents({ id: contactId, eventId: IdToDelete })
            );

            setTimeout(() => {
              setUndoDelete(false);
            }, 2000);
          }
        }}
        bottomNav={isMobile}
      />
    </>
  );
};

export default DateList;
