import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { UserSliceType } from "../../../utils";
import { RootState, baseURL, fetchActivePlan, AXIOS_CLIENT } from "../../";
import axios, { AxiosError } from "axios";

const initialState: UserSliceType = {
  user: {
    id: 0,
    first_name: "",
    last_name: "",
    name: "",
    email: "",
    nickname: null,
    image_url: "",
    subscribed: false,
    is_new_account: true,
    contacts_fetched: false,
    plan_id: null,
  },
  loggedIn: false,
  access_token: "",
  client: "",
  uid: "",
};

/*******************THUNKS************************/

/**
 * User login function
 */
export const login = createAsyncThunk("user/login", async () => {
  try {
    // Get sign up url
    const signupUrl = await axios.get(`${baseURL}auth/sign_up`);

    // open google scoped url in the same tab
    window.open(signupUrl.data.url, "_self");
  } catch (err) {
    throw err;
  }
});

/**
 * authorise the user
 */
export const authorize = createAsyncThunk("user/authorize", async () => {
  try {
    // Get code from parameters of redirect url
    let arr = window.location.search.split("&");
    let rawCode = arr.find((item) => item.includes("code"));

    let code = rawCode?.slice(rawCode.indexOf("=") + 1);

    let rawAuthUser = arr.find((item) => item.includes("authuser"));

    let auth_user = rawAuthUser?.slice(rawAuthUser.indexOf("=") + 1);

    // make a post request to get the user object and headers
    const response = await axios.post(`${baseURL}auth/`, { code, auth_user });

    // get the auth headers
    const { "access-token": access_token, client, uid } = response.headers;

    // redirect user to home page
    window.location.href = window.location.origin;

    //send data to redux store
    return { user: response.data, headers: { access_token, client, uid } };
  } catch (err: any) {
    let error: AxiosError<any> = err;
    if (error.response) {
      if (error.response.status === 401) {
        window.location.href = error.response.data.url as string;
      }
      throw err;
    } else throw err;
  }
});

/*******************SLICE*********************/

export const reFetchSettings = createAsyncThunk("user/settings", async () => {
  const response = await AXIOS_CLIENT.get("setting");
  return response.data;
});

/**
 *  user slice, actions and handle fetched data
 */
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    signout: () => {
      // deliberately left empty
    },
  },
  extraReducers(builder) {
    builder
      .addCase(authorize.fulfilled, (state, action) => {
        // set state with
        if (action.payload) {
          const { access_token, client, uid } = action.payload.headers;
          state = {
            user: action.payload.user,
            access_token,
            client,
            uid,
            loggedIn: true,
          };
        }
        return state;
      })
      .addCase(reFetchSettings.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(reFetchSettings.fulfilled, (state, action) => {
        state.user.subscribed = action.payload.setting.subscribed;
        state.isLoading = false;
      })
      .addCase(fetchActivePlan.fulfilled, (state, action) => {
        state.user = action.payload;
      });
  },
});

/********************SELECTORS*********************/

/**
 * Select user data
 */
export const selectUserData = createSelector(
  [(state: RootState) => state.user.user],
  (user) => user
);
export const selectUsername = createSelector(
  [(state: RootState) => state.user.user.first_name],
  (firstname) => firstname
);

export const selectLoggedInStatus = createSelector(
  [(state: RootState) => state.user.loggedIn],
  (loggedIn) => loggedIn
);
export const selectFirstTimeUser = createSelector(
  [(state: RootState) => state.user.user.is_new_account],
  (is_new_account) => is_new_account
);
export const selectIsSubscribed = createSelector(
  [(state: RootState) => state.user?.user?.subscribed],
  (isSuscribed) => isSuscribed
);
export const selectUserPlanId = createSelector(
  [(state: RootState) => state.user.user.plan_id],
  (plan_id) => plan_id
);
export const selectUserLoading = createSelector(
  [(state: RootState) => state.user.isLoading],
  (isLoading) => isLoading
);

export const { signout } = userSlice.actions;

export default userSlice.reducer;
