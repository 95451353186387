import {
  Avatar,
  Typography,
  Button,
  ButtonProps,
  useMediaQuery,
  Theme,
} from "@mui/material";

const ActionTrigger = (
  props: ButtonProps & {
    /**
     * Single alphabet to be rendered beside the text on white button
     */
    letter: string;
    /**
     * Text underline : describes the button's action
     */
    description?: string;
  }
) => {
  const isLargeScreen = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up("sm")
  );
  return (
    <Button
      sx={{
        "&:hover": {
          backgroundColor: "inherit",
        },
        display: "flex",
        justifyContent: "space-between",
        width: "max-content",
        padding: "0 !important",
      }}
      tabIndex={1}
      {...props}
    >
      <Typography
        sx={{
          textDecoration: "underline",
          marginRight: 1,
          fontSize: 13,
          fontWeight: 600,
        }}
        className="faded"
      >
        {props.description}
      </Typography>
      {isLargeScreen ? (
        <Avatar
          variant="rounded"
          sx={{
            backgroundColor: "white",
            color: "#30333B",
            boxShadow: "0px 1px 3px 0px #0000004D",
            fontSize: 13,
            height: 25.6,
            width: 32,
          }}
        >
          {props.letter.toUpperCase()}
        </Avatar>
      ) : null}
    </Button>
  );
};

export default ActionTrigger;
