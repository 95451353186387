import { ClickAwayListener, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ActionContainer, ActionTrigger } from "../../";
import {
  selectNotesInputVisisbility,
  toggleNoteInput,
  useAppDispatch,
  useAppSelector,
  updateContactNotes,
  setContactNote,
  SelectContactById,
} from "../../../appStore";
import { ActionComponentProps } from "../../../utils";
/**
 * Allows the editing, updating of notes associated with a contact and displays them with conditional logic
 */
const NotesAction = ({ contact }: ActionComponentProps) => {
  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  const dispatch = useAppDispatch();
  const noteState =
    useAppSelector((state) => SelectContactById(state, contact.id))?.notes ||
    "";
  const showTextArea = useAppSelector(selectNotesInputVisisbility);
  const [isNoteChanged, setIsNoteChanged] = useState(false);

  useEffect(() => {
    if (showTextArea) {
      // when the input is visible, set focus, bring the caret position to the end
      inputRef.current?.focus();
      inputRef.current?.setSelectionRange(-1, -1);
    } else if (isNoteChanged) {
      // when focus leaves the input submit the note
      dispatch(updateContactNotes(contact.id));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTextArea]);

  // set event listener to submit note when the "enter" key is pressed
  const handleEnterKey = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      if (e.ctrlKey || e.shiftKey) {
        // do nothing
      } else {
        dispatch(toggleNoteInput(false));
      }
    }
    setIsNoteChanged(true);
  };

  // controlled input for note textarea
  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target as HTMLTextAreaElement;
    dispatch(setContactNote({ id: contact.id, note: value }));
  };

  const handleClickAway = () => {
    dispatch(toggleNoteInput(false));
  };

  return (
    <>
      <ActionContainer
        id="notes-action-form"
        title="Notes"
        trigger={
          <ActionTrigger
            letter="N"
            description="Add note"
            onClick={() => dispatch(toggleNoteInput())}
            onTouchEnd={() => dispatch(toggleNoteInput())}
          />
        }
        // only show description if there is no note
        description={
          !noteState
            ? "  You don’t have any notes yet!"
            : ""
        }
      >
        {!showTextArea ? (
          <Typography mt={2} fontSize={12} className="preserve-break">
            {noteState}
          </Typography>
        ) : (
          <>
            <ClickAwayListener
              onClickAway={handleClickAway}
              mouseEvent="onClick"
              touchEvent="onTouchEnd"
            >
              <div>
                <textarea
                  placeholder="Type here..."
                  value={noteState}
                  onChange={onChange}
                  tabIndex={1}
                  ref={inputRef}
                  onKeyDown={handleEnterKey}
                  onBlur={() => {
                    handleClickAway();
                  }}
                />
              </div>
            </ClickAwayListener>
            <Typography variant="body2" fontSize={10} sx={{ color: "#B7B7B7" }}>
              changes saved automatically
            </Typography>
          </>
        )}
      </ActionContainer>
    </>
  );
};

export default NotesAction;
