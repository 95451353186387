import { useAppSelector } from "../../appStore";
import { ErrorLayout } from "..";

/**
 * @returns Dialog to display error when the connection is lost
 */
const ConnectionLostError = () => {
  const openState = useAppSelector((state) => state.errors.connectionLostError);

  return (
    <ErrorLayout
      openState={openState}
      title="Problem connecting"
      description="Please check your internet connection and try again"
      button1Text="Try again"
    />
  );
};

export default ConnectionLostError;
