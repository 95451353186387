import { Stack, Typography } from "@mui/material";

type Props = {
  /**
   * ReactNode or Image string. displays as the icon before the text
   */
  icon: string | JSX.Element;
  /**
   * Text string or JSX Element. displays as a child of the typography MUI component
   */
  text: string | JSX.Element;
  /**
   * Can be any valid color in css or MUI. determines the text color of the component
   */
  color?: string;
};

/**
 * Displays text and Icons in a simple horizontal stack
 */

const IconText = ({ icon, text, color }: Props) => {
  return (
    <Stack direction="row" spacing={1}>
      {typeof icon === "string" ? (
        <img src={icon} alt="" width={24} height={24} />
      ) : (
        icon
      )}
      <Typography sx={{ color: color }}>{text}</Typography>
    </Stack>
  );
};

export default IconText;
