import { openSignoutConfirmationDialog, useAppDispatch } from "../../appStore";
import { Typography } from "@mui/material";

const SignOut = () => {
  const dispatch = useAppDispatch();
  return (
    <Typography
      sx={{
        color: "error.main",
        textDecoration: "underline",
        px: 0,

        cursor: "pointer",
      }}
      fontSize={{ xs: 13, sm: 15 }}
      onClick={() => {
        dispatch(openSignoutConfirmationDialog());
      }}
    >
      Sign out
    </Typography>
  );
};

export default SignOut;
