import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../store";

export const SelectDigest = createSelector(
  [(state: RootState) => state.actions?.present],
  (digest) => digest
);

export const SelectActiveDigestId = createSelector(
  [(state: RootState) => state.actions?.present],
  (digest) => digest.activeDigestId
);

export const SelectEmailDigestId = createSelector(
  [(state: RootState) => state.actions?.present],
  (digest) => digest.emailDigestId
);

export const SelectDeleteContactLoading = createSelector(
  [(state: RootState) => state.actions?.present],
  (digest) => digest.deleteContactLoading
);

export const SelectReviewContactLoading = createSelector(
  [(state: RootState) => state.actions?.present],
  (digest) => digest.reviewContactLoading
);

export const SelectActiveDigestLoading = createSelector(
  [(state: RootState) => state.actions?.present],
  (digest) => digest.activeDigestLoading
);

export const SelectAllContacts = createSelector(
  [(state: RootState) => state.actions.present?.contacts],
  (contact) => contact
);

export const SelectContactById = createSelector(
  [SelectAllContacts, (state: RootState, id: number) => id],
  (contacts, id) => contacts?.find((item) => item.id === id)
);

export const SelectReviewedContacts = createSelector(
  [SelectAllContacts],
  (contacts) => contacts?.filter((item) => item.status === "reviewed")
);

export const SelectSnoozedContacts = createSelector(
  [SelectAllContacts],
  (contacts) => contacts?.filter((item) => item.status === "snoozed")
);

export const SelectPendingContacts = createSelector(
  [SelectAllContacts],
  (contacts) => contacts?.filter((item) => item.status === "pending")
);

export const SelectDeletedContacts = createSelector(
  [SelectAllContacts],
  (contacts) => contacts?.filter((item) => item.status === "deleted")
);

export const SelectContactUnderReview = createSelector(
  [SelectAllContacts],
  (contacts) => contacts?.find((item) => item.status === "under_review")
);

export const selectAllContactsToReview = createSelector(
  [SelectPendingContacts, SelectContactUnderReview],
  (pending, underReview) => {
    if (underReview) pending?.push(underReview);
    return pending;
  }
);

export const selectAllContactsToReviewWithUrlID = createSelector(
  [selectAllContactsToReview, (state: RootState, id) => id],
  (contactsToReview, id) => {
    let index = contactsToReview.findIndex((item) => item.id === parseInt(id));

    if (index !== undefined && index >= 0) {
      let contact = contactsToReview[index];

      let arr = contactsToReview.filter((a, i) => i !== index);

      arr.push(contact);

      return arr;
    } else return contactsToReview;
  }
);

export const ReviewStepperSelector = createSelector(
  [
    SelectAllContacts,
    SelectReviewedContacts,
    SelectSnoozedContacts,
    SelectDeletedContacts,
  ],
  (contacts, reviewed, snoozed, deleted) => [
    contacts?.length,
    reviewed?.length + snoozed?.length + deleted?.length,
  ]
);

export const SelectContactEvents = createSelector(
  [SelectAllContacts, (state: RootState, id: number) => id],
  (contacts, id) => contacts?.find((item) => item.id === id)?.google_events
);

/**
 * checks if the "reviewed successfully" screen should be shown
 * @returns boolean
 */
export const SelectAreContactsAvailable = createSelector(
  [
    SelectAllContacts,
    SelectReviewedContacts,
    SelectSnoozedContacts,
    SelectDeletedContacts,
    (state: RootState) => state?.actions?.present,
  ],
  (contacts, reviewed, snoozed, deleted, actions) =>
    // return true if contacts total length and the length of snoozed and reviewed contacts are equal
    (contacts?.length ===
      reviewed?.length + snoozed?.length + deleted?.length &&
      contacts?.length !== 0) ||
    (actions && "notice" in actions)
);

export const selectLinkedInUrl = createSelector(
  [SelectContactById],
  (id) => id?.google_urls.find((item) => item.url_type === "linked_in")?.url
);
