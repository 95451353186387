import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { LabelObjectType } from "../../../utils";
import { AXIOS_CLIENT } from "../../";

const initialState: LabelObjectType[] = [];

/*******************THUNKS************************/

/**
 * Filter labels function
 */
export const filterLabels = createAsyncThunk(
  "label/filter",
  async (param: string) => {
    const response = await AXIOS_CLIENT.post("labels/filter", {
      filter: param,
    });

    return response.data;
  }
);

/*******************SLICE************************/

/**
 * Label slice
 */
const labelSlice = createSlice({
  name: "label",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      filterLabels.fulfilled,
      (state, action: PayloadAction<LabelObjectType[]>) => {
        state = action.payload;
        return state;
      }
    );
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = labelSlice.actions;

export default labelSlice.reducer;
