import arrowAnticlockwisesvg from "./arrowAnticlockwise.svg";
import checkRingRoundsvg from "./checkRingRound.svg";
import checkRingRoundThinsvg from "./checkRingRoundThin.svg";
import infosvg from "./info.svg";
import mastercardLogosvg from "./mastercardLogo.svg";
import visacardLogosvg from "./visacardLogo.svg";
import progressIconsvg from "./progressIcon.svg";
import userCirclePlussvg from "./userCirclePlus.svg";
import userCircleDocumentPlussvg from "./userCircleDocumentPlus.svg";

/**
 * regular type for the html image tag used for custom icons
 */
export type IconProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;
/**
 * Returns the base element for creating a custom Icon componentt
 *
 * @param props - all html image element props are valid
 * @returns JSX.Element
 */
const Icon = (props: IconProps) => {
  return (
    <img {...props} alt={props.alt} width={props.width || 24} height={props.height || 24} />
  );
};

export const ArrowAnticlockwise = (props: IconProps) =>
  Icon({ src: arrowAnticlockwisesvg, ...props });

export const CheckRingRound = (props: IconProps) =>
  Icon({ src: checkRingRoundsvg, ...props });

export const CheckRingRoundThin = (props: IconProps) =>
  Icon({ src: checkRingRoundThinsvg, ...props });

export const Info = (props: IconProps) => Icon({ src: infosvg, ...props });

export const MastercardLogo = (props: IconProps) =>
  Icon({ src: mastercardLogosvg, ...props });

export const VisacardLogo = (props: IconProps) =>
  Icon({ src: visacardLogosvg, ...props });

export const ProgressIcon = (props: IconProps) =>
  Icon({ src: progressIconsvg, ...props });

export const UserCirclePlus = (props: IconProps) =>
  Icon({ src: userCirclePlussvg, ...props });

export const UserCircleDocumentPlus = (props: IconProps) =>
  Icon({ src: userCircleDocumentPlussvg, ...props });

export default Icon;
