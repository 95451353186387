import { Avatar, Typography, Stack } from "@mui/material";
import { useAppSelector, selectUsername, selectUserData } from "../../appStore";

/**
 * Displays the picture of the logged in user, name and a simple hello message
 * @todo add image | avatar prop and name prop to get user information dynamically
 */
const UserBasicInfo = () => {
  const userData = useAppSelector(selectUserData);
  const username = useAppSelector(selectUsername);
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Avatar
        variant="rounded"
        alt="user avatar"
        src={userData.image_url}
        imgProps={{
          referrerPolicy: "no-referrer",
        }}
        sx={{ width: { xs: 78, lg: 100 }, height: { xs: 78, lg: 100 } }}
      />
      <Typography variant="h5" fontWeight={600} fontSize={{ xs: 23, sm: 28 }}>
        Hello, {username} 👋
      </Typography>
    </Stack>
  );
};

export default UserBasicInfo;
