import { Grid, Typography, Button, Box } from "@mui/material";
import {
  TagSettings,
  ReviewFrequencySettings,
  ContactNumberSettings,
} from "../..";
import { useAppDispatch, updateSettings } from "../../../appStore";

/**
 * @todo uncomment premium check for upgrade overlay
 * @returns
 */
const SettingsTop = () => {
  // from redux state
  const dispatch = useAppDispatch();

  return (
    <Box sx={{ position: "relative" }}>
      {/* {isPremium ? null : <UpgradeOverlay />} */}
      <Typography
        variant="h3"
        sx={{
          mt: { xs: 2, sm: 4 },
          mb: { xs: "20px", sm: 4 },
        }}
      >
        Lets setup your settings...{" "}
      </Typography>
      <Grid container mb={4} columnSpacing={10}>
        <ReviewFrequencySettings />
        <TagSettings />
        <ContactNumberSettings />
      </Grid>
      <Button
        variant="contained"
        sx={{
          width: { xs: "100%", sm: "initial" },
          mt: 5,
          mb: { xs: "20px", sm: 5 },
        }}
        onClick={() => {
          dispatch(updateSettings(true));
        }}
      >
        Save changes
      </Button>
    </Box>
  );
};

export default SettingsTop;
