import { createSlice } from "@reduxjs/toolkit";
import { falsifyObject, DialogSliceType } from "../../../utils";
import { deleteContact } from "../../";

// type DialogDispatchTypes =

// relying on type inference
const initialState: DialogSliceType = {
  addPaymentMethod: false,
  contactDeleted: false,
  deleteContact: false,
  deletePaymentMethod: false,
  managePaymentMethod: false,
  paymentMethodAdded: false,
  subscriptionPrompt: false,
  upgradePlan: false,
  snoozeContact: false,
  signoutConfirmation: false,
  olderDigestClicked: false,
};

function openDialog(name: keyof DialogSliceType, state: DialogSliceType) {
  state = falsifyObject(state);
  if (typeof state[name] === "boolean") {
    state = { ...state, [name]: true };
  }

  return state;
}
export const dialogSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    openAddPaymentMethodDialog: (state) => {
      return openDialog("addPaymentMethod", state);
    },
    openContactDeletedDialog: (state) => {
      return openDialog("contactDeleted", state);
    },
    openDeleteContactDialog: (state) => {
      return openDialog("deleteContact", state);
    },
    openDeletePaymentMethodDialog: (state) => {
      return openDialog("deletePaymentMethod", state);
    },
    openManagePaymentMethodDialog: (state) => {
      return openDialog("managePaymentMethod", state);
    },
    openPaymentMethodAddedDialog: (state) => {
      return openDialog("paymentMethodAdded", state);
    },
    openSubscriptionPromptDialog: (state) => {
      return openDialog("subscriptionPrompt", state);
    },
    openUpgradePlanDialog: (state) => {
      return openDialog("upgradePlan", state);
    },
    openSnoozeContactDialog: (state) => {
      return openDialog("snoozeContact", state);
    },
    openSignoutConfirmationDialog: (state) => {
      return openDialog("signoutConfirmation", state);
    },
    openOlderDigestClickedDialog: (state) => {
      return openDialog("olderDigestClicked", state);
    },
    closeDialog: (state) => {
      state = falsifyObject(state);
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deleteContact.fulfilled, (state) => {
      return openDialog("contactDeleted", state);
    });
  },
});

export const {
  openAddPaymentMethodDialog,
  openContactDeletedDialog,
  openDeleteContactDialog,
  openDeletePaymentMethodDialog,
  openManagePaymentMethodDialog,
  openPaymentMethodAddedDialog,
  openSubscriptionPromptDialog,
  openUpgradePlanDialog,
  openSnoozeContactDialog,
  openSignoutConfirmationDialog,
  openOlderDigestClickedDialog,
  closeDialog,
} = dialogSlice.actions;

export default dialogSlice.reducer;
