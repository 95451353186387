import { useAppSelector } from "../../appStore";
import { ErrorLayout } from "..";

/**
 * @returns Dialog to display error when the update settings action fails
 */
const UpdateSettingsError = () => {
  const openState = useAppSelector((state) => state.errors.updateSettingsError);

  return (
    <ErrorLayout
      openState={openState}
      title="Can’t update settings"
      description="Please refresh the page to try to update settings again. "
    />
  );
};

export default UpdateSettingsError;
