import { useAppSelector } from "../../appStore";
import { ErrorLayout } from "..";

/**
 * @returns Dialog to display error when the update contact notes action fails
 */
const UpdateContactNotesError = () => {
  const openState = useAppSelector(
    (state) => state.errors.updateContactNotesError
  );

  return (
    <ErrorLayout
      openState={openState}
      title="Can’t update notes"
      description="Can’t update notes"
    />
  );
};

export default UpdateContactNotesError;
