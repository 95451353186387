import logo from "../../assets/images/logo.png";
import whiteLogo from "../../assets/images/logo-white.svg";
import { Theme, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";

type Props = {
  /**
   * selects the logo color to display defaults to the blue logo
   */
  type?: "white" | "blue";
};

/**
 * Displays the logo
 */
const Logo = ({ type }: Props): JSX.Element => {
  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );

  /** return logo in primary color with optional type property to change logo color */
  if (type === "white") {
    return (
      <img src={whiteLogo} height="32px" width="115px" alt="recontact logo" />
    );
  }

  return (
    <Link to="/" tabIndex={-1}>
      <img
        src={logo}
        alt="recontact logo"
        style={{
          width: isMobile ? "120px" : "168px",
          height: isMobile ? "38px" : "55px",
        }}
      />
    </Link>
  );
};

export default Logo;
