import { Stack, IconButton, Theme, useMediaQuery } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { EventInputType } from "../../../utils";
import { useRef } from "react";
// import {
//   toggleDateInputFocus,
//   useAppDispatch,
//   useAppSelector,
// } from "../../../appStore";

type Props = {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  data: EventInputType;
  show: boolean;
};

const DateForm = (props: Props) => {
  const dateRef = useRef<HTMLInputElement | null>(null);
  // const dateFocus = useAppSelector((state) => state.actionInput.dateInputFocus);
  // const dispatch = useAppDispatch();
  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );
  const labelStyle = {
    fontWeight: 500,
    fontSize: "13px",
    lineHeight: "20px",
    letterSpacing: "0.25px"
  };

  // useEffect(() => {
  //   if (dateFocus) dateRef.current?.focus();
  // }, [dateFocus]);
  const blurField = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Escape") {
      event.currentTarget.blur()
    }
  }
  return (
    <>
      <form
        onSubmit={props.onSubmit}
        style={{
          width: "100%",
          display: "flex",
          gap: "10px",
          flexWrap: "wrap",
        }}
      >
        <div style={isMobile ? {width: "100%"} : {}}>
          <label htmlFor="date" style={labelStyle}>Date</label>
          <input
            type="date"
            tabIndex={1}
            name="event_time"
            value={props.data.event_time}
            onChange={props.onChange}
            style={{
              maxWidth: isMobile ? "100%" : "264px",
            }}
            required
            ref={dateRef}
            onKeyDown={blurField}
            onBlur={() => {
              // dispatch(toggleDateInputFocus(false));
            }}
          />
        </div>
        <div style={isMobile ? {width: "100%"} : {}}>
          <label htmlFor="date" style={labelStyle}>Event</label>
          <Stack direction="row" spacing={2}>
            <input
              type="text"
              placeholder="What happened"
              tabIndex={1}
              name="label"
              value={props.data.label}
              onChange={props.onChange}
              onKeyDown={blurField}
            />
            <IconButton type="submit" tabIndex={1}>
              <AddCircleOutlineIcon
                color="primary"
                sx={{
                  fontSize: "26px !important",
                }}
              />
            </IconButton>
          </Stack>
        </div>
      </form>
    </>
  );
};

export default DateForm;
