import { useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, Typography } from "@mui/material";
import { CheckRingRound } from "../../assets/icons";
import {
  useAppDispatch,
  useAppSelector,
  closeDialog,
  openManagePaymentMethodDialog,
} from "../../appStore";

const PaymentMethodAddedDialog = () => {
  const dispatch = useAppDispatch();
  const openState = useAppSelector((state) => state.dialog.paymentMethodAdded);
  const close = () => {
    dispatch(closeDialog());
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (openState) {
      timer = setTimeout(() => {
        // dispatch(closeDialog());
        dispatch(openManagePaymentMethodDialog());
      }, 1000);
    }
    return () => {
      clearTimeout(timer);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openState]);

  return (
    <Dialog
      open={openState}
      sx={{ textAlign: "center" }}
      onClose={close}
      maxWidth="md"
    >
      <DialogTitle>
        <CheckRingRound width={36} height={36} />
      </DialogTitle>
      <DialogContent sx={{ px: "54px" }}>
        <Typography variant="h3" fontSize={23} fontWeight={700}>
          Your payment mothod has been added{" "}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default PaymentMethodAddedDialog;
