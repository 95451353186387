import { Typography } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const ToggleExpandButton = ({
  collapse,
  toggleCollapse,
}: {
  collapse: boolean;
  toggleCollapse: () => void;
}) => {
  return (
    <Typography
      tabIndex={1}
      sx={{
        color: "primary.main",
        cursor: "pointer",
        mt: { xs: "21px", sm: "11px" },
        textDecoration: "underline",
        fontSize: "13px",
        lineHeight: "130%",
        letterSpacing: "0.0125em",
      }}
      onClick={toggleCollapse}
    >
      Show {collapse ? "less" : "more"}
      {collapse ? (
        <ExpandLess sx={{ mb: -1 }} />
      ) : (
        <ExpandMore sx={{ mb: -1 }} />
      )}
    </Typography>
  );
};

export default ToggleExpandButton;
