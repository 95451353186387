import { createSlice } from "@reduxjs/toolkit";
import { PaymentsSliceType } from "../../../utils";
import { fetchActivePlan, fetchPlans } from "./thunks";

const initialState: PaymentsSliceType = {
  plans: [
    {
      id: "monthly",
      interval: "month",
      name: "ReContact Monthly Plan",
      amount: "",
      trial: "",
    },
    {
      id: "yearly",
      interval: "year",
      name: "ReContact Yearly Plan",
      amount: "",
      trial: "",
    },
  ],
  plan_id: "",
  trial_active: false,
  plan_details: {
    id: "",
    interval: "",
    name: "",
    amount: "",
    trial: "",
  },
  
};

const paymentSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchActivePlan.fulfilled, (state, action) => {
        state.plan_details = action.payload.plan_details;
        state.plan_id = action.payload.plan_id;
        state.trial_active = action.payload.trial_active;
        return state;
      })
      .addCase(fetchPlans.fulfilled, (state, action) => {
        if (action.payload.length) {
          state.plans = action.payload;
        }
        return state;
      });
    //   .addCase(fetchPaymentStatus.fulfilled, (state, action) => {
    //     state.status = action.payload.status;
    //     return state;
    //   });
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = paymentSlice.actions;

export default paymentSlice.reducer;
