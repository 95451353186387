/**
 * @param dateFrom
 * @param dateTo
 * @returns number
 */
const monthDifference = (monthFrom: number, yearFrom: number, dateTo: Date): number => {
  return (
    dateTo.getMonth() -
    monthFrom +
    12 * (dateTo.getFullYear() - yearFrom)
  );
};

export default monthDifference;
