import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Chip,
  Typography,
  Box,
  Checkbox,
} from "@mui/material";
import { IconText } from "..";
import { CheckRingRound } from "../../assets/icons";
import Close from "@mui/icons-material/Close";
import { useAppDispatch, useAppSelector, closeDialog } from "../../appStore";

const SubscriptionPromptDialog = () => {
  const dispatch = useAppDispatch();
  const openState = useAppSelector((state) => state.dialog.subscriptionPrompt);
  const close = () => {
    dispatch(closeDialog());
  };

  return (
    <Dialog open={openState} onClose={close} sx={{ textAlign: "center" }}>
      <Close
        sx={{
          position: "absolute",
          top: { xs: 12, sm: 36 },
          right: { xs: 12, sm: 36 },
          color: "#222628",
        }}
        onClick={close}
      />
      <DialogTitle sx={{ px: "91px" }}>
        Sorry - You're on our free plan and have already reviewed all your
        contacts this month
      </DialogTitle>
      <DialogContent sx={{ mt: 1, px: "40px" }}>
        <Typography fontSize={20} gutterBottom>
          WANT MORE?
        </Typography>
        <Chip
          variant="filled"
          color="info"
          label="10% OFF TODAY"
          sx={{ fontWeight: 600, fontSize: 14 }}
        />
        <Typography variant="h4" mt={4}>
          Just <span style={{ color: "var(--info)" }}>$13.50/year</span> (billed
          annually)
        </Typography>
        <Box
          my={4}
          sx={{
            textAlign: "left",
            "> *": {
              mb: 3,
            },
          }}
        >
          <IconText
            icon={<CheckRingRound />}
            text="Choose how many contacts you want to review"
          />
          <IconText
            icon={<CheckRingRound />}
            text="Choose the frequency to review contacts"
          />
          <IconText icon={<CheckRingRound />} text="Unlimited contact review" />

          <div>
            <Checkbox checked />{" "}
            <Typography component="label" display="inline-block">
              Don’t ask me again
            </Typography>
          </div>
        </Box>
        <Button fullWidth variant="contained" sx={{ mt: 2 }}>
          Add card
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default SubscriptionPromptDialog;
