import { Grid, Typography, Chip, Stack } from "@mui/material";
import {
  useAppSelector,
  useAppDispatch,
  selectSettingFrequencies,
  selectSettingState,
  setFrequency,
  toggleCustomFrequency,
} from "../../../appStore";
/**
 * Displays the frequency review component
 *
 * Allowing users choose how frequently they get prompted
 */
const ReviewFrequencySetting = () => {
  const dispatch = useAppDispatch();

  // const [showInfo, setShowInfo] = useState<boolean>(false);

  const settingFrequencies = useAppSelector(selectSettingFrequencies);
  const settingState = useAppSelector(selectSettingState);

  const customFrequency = settingState.custom_frequency;

  const isCustomFrequency = settingState.is_custom_frequency;

  const changeFrequency = (e: React.ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    dispatch(setFrequency(parseInt(value)));
  };

  return (
    <Grid item md={6} xs={12}>
      <Typography variant="h5" sx={{ mb: "10px", maxWidth: "388px" }}>
        How frequently do you want to review contacts?
      </Typography>

      <Stack
        direction="row"
        width={{ sm: "450px" }}
        flexWrap="wrap"
        sx={{
          "> *": {
            margin: "10px 10px 0 0 !important",
          },
        }}
      >
        {/* map predefined frequencies */}

        {settingFrequencies.map((item, index) => (
          <Chip
            key={index}
            label={item}
            color={
              item === settingState.frequency && !isCustomFrequency
                ? "primary"
                : "secondary"
            }
            onClick={() => {
              dispatch(setFrequency(item));
            }}
          />
        ))}

        {/* The custom frequency buttons and input group starts here */}

        {isCustomFrequency ? (
          <Chip
            label={`Every ${customFrequency} Days`}
            color="primary"
            onClick={() => {
              dispatch(toggleCustomFrequency(false));
            }}
          />
        ) : (
          <>
            <Stack
              spacing={0.5}
              direction="row"
              sx={{
                mx: "0px !important",
                "> *": {
                  cursor: "default !important",
                },
              }}
            >
              <Chip
                label="Every"
                color={
                  customFrequency && customFrequency > 30
                    ? "error"
                    : "secondary"
                }
                sx={
                  customFrequency && customFrequency > 30
                    ? {
                        color: "error.main",
                        backgroundColor: "var(--error-light)",
                      }
                    : {
                        color: "secondary",
                        backgroundColor: "secondary",
                      }
                }
                style={{ background: 'none' }}
              />
              <input
                type="number"
                placeholder="e.g. 5,10"
                style={{
                  border:
                    customFrequency && customFrequency > 30
                      ? "2px solid var(--error-light)"
                      : "2px solid var(--secondary)",
                  width: '81px'
                }}
                className="customTextField"
                value={customFrequency || ""}
                onChange={changeFrequency}
                onBlur={() => {
                  dispatch(toggleCustomFrequency(true));
                  // setShowInfo(false);
                }}
                onFocus={() => {
                  dispatch(setFrequency(parseInt("")));
                  // setShowInfo(true);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    dispatch(toggleCustomFrequency(true));
                  }
                }}
              />
              <Chip
                label="days"
                color={
                  customFrequency && customFrequency > 30
                    ? "error"
                    : "secondary"
                }
                sx={
                  customFrequency && customFrequency > 30
                    ? {
                        color: "error.main",
                        backgroundColor: "var(--error-light)",
                      }
                    : {
                        color: "secondary",
                        backgroundColor: "secondary",
                        cursor: customFrequency && "pointer !important",
                      }
                }
                style={{ background: 'none' }}
              />
            </Stack>
          </>
        )}
        {/* The custom frequency buttons and input group ends here */}
      </Stack>
      {/* {showInfo ? ( */}
      <Typography
        mt="12px"
        fontSize={10}
        variant="body2"
        maxWidth="240px"
        sx={{ color: "var(--light-text)" }}
      >
        We limit the spacing to 30 days so you can benefit from regular
        repetition
      </Typography>
      {/* ) : null} */}
    </Grid>
  );
};

export default ReviewFrequencySetting;
