import {
  ActionButtonGroup,
  ContactBasicInfo,
  NotesAction,
  TagAction,
  CustomPaper,
  DateAction,
  DeleteContactDialog,
  FooterLinks,
} from "../..";
import { Stack, Divider, Box } from "@mui/material";
import { ActionComponentProps, handleHotKey } from "../../../utils";
import {
  useAppDispatch,
  toggleContactStarState,
  snoozeContact,
  incrementMailingStat,
  setContactStar,
  reviewContact,
  useAppSelector,
  SelectDeleteContactLoading,
  SelectReviewContactLoading,
} from "../../../appStore";
import { GlobalHotKeys } from "react-hotkeys";
import { useEffect, useLayoutEffect, useRef } from "react";
import { useMediaQuery, Theme } from "@mui/material";
import css from "./singleContactFullComponent.module.css";
import NextContactPlaceholder from "./nextContactPlaceholder";
/**
 *
 * @returns complete component containing a single contact's information and all actions
 */
const SingleContactFullComponent = ({
  contact,
  disabled,
  onReview,
}: ActionComponentProps) => {
  const dispatch = useAppDispatch();
  const desktopRef = useRef<HTMLDivElement>(null);
  const isDeleteDialogOpen = useAppSelector((state) => state.dialog.deleteContact);
  const deleteLoading = useAppSelector(SelectDeleteContactLoading);
  const reviewLoading = useAppSelector(SelectReviewContactLoading);
  const isDeletingContact = isDeleteDialogOpen || deleteLoading;
  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );
  useEffect(() =>{
    desktopRef.current?.scrollIntoView({behavior: "smooth"});
  }, []);
  useLayoutEffect(() => {
    if (desktopRef.current && !isMobile) {
      desktopRef.current.className = css.singleContactWrapper;
    }
  }, [contact.id, isMobile]);

  // key bindings for action page
  const keyMap = {
    STAR_CONTACT: ["f", "F"],
    SNOOZE_CONTACT: ["s", "S"],
    OPEN_IN_GOOGLE_CONTACTS: ["e", "E"],
    REVIEW_CONTACT: ["a", "A", "Enter"],
    SEND_MAIL: ["m", "M"],
  };

  const handlers = {
    STAR_CONTACT: handleHotKey(() => {
      dispatch(setContactStar(contact.id));
      dispatch(toggleContactStarState(contact.id));
    }),
    SNOOZE_CONTACT: handleHotKey(() => dispatch(snoozeContact(contact.id))),
    OPEN_IN_GOOGLE_CONTACTS: handleHotKey(() => {
      dispatch(incrementMailingStat());
      window.open(contact.link_to_contact, "_blank");
    }),
    REVIEW_CONTACT: handleHotKey(() => {
      if (!(isDeletingContact || reviewLoading)) {
        dispatch(reviewContact(contact.id));
        isMobile && onReview?.();
        if (desktopRef.current && !isMobile) {
          desktopRef.current.className = "";
        }
        desktopRef.current?.scrollIntoView({behavior: "smooth"});
      }
    }),
    SEND_MAIL: handleHotKey(() => {
      if (contact.email) {
        dispatch(incrementMailingStat());
        window.open(`mailto:${contact.email}`);
      }
    }),
  };

  return (
    <div ref={desktopRef} style={{paddingBottom: isMobile ? "3rem" : "0rem"}}>
      <CustomPaper marginBottom={2}>
        {disabled ? 
          <Stack
            sx={{
              margin: { xs: "16px 0 70px", lg: "0 0 0 148px" },
              position: {xs: "sticky"},
              top: {xs: "0"},
            }}
          >
            <ContactBasicInfo contact={contact} disabled={disabled} />
            <StyledDivider />
            <NextContactPlaceholder />
          </Stack>
        : (
          <>
            <ContactBasicInfo contact={contact} disabled={disabled} />
            <Stack
              sx={{
                margin: { xs: "16px 0 20px", lg: "0 0 0 148px" },
              }}
            >
              <StyledDivider />
              <TagAction contact={contact} />
              <StyledDivider />
              <NotesAction contact={contact} />
              <StyledDivider />
              <DateAction contact={contact} />
              {/*  */}
            </Stack>
            {!isMobile && <ActionButtonGroup contact={contact} contactComponentDiv={desktopRef.current} />}
            <DeleteContactDialog id={contact.id} />
            <GlobalHotKeys keyMap={keyMap} handlers={handlers} allowChanges />
          </>
        )}
      </CustomPaper>
      {
        isMobile &&
        <Box sx={{ mb: "30px", mt: "auto" }}>
          <FooterLinks center />
        </Box>
      }
    </div>
  );
};

export default SingleContactFullComponent;

const StyledDivider = () => {
  return (
    <Divider
      light
      sx={{
        pt: "20px",
        mb: "20px",
      }}
    />
  );
};
