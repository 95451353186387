import { useAppSelector } from "../../appStore";
import { ErrorLayout } from "..";

/**
 * @returns Dialog to display error when the delete contact label action fails
 */
const DeleteContactLabelError = () => {
  const openState = useAppSelector(
    (state) => state.errors.deleteContactLabelError
  );

  return (
    <ErrorLayout
      openState={openState}
      title="Can’t delete conatct label"
      description="Please refresh the page to try deleting the label again."
    />
  );
};

export default DeleteContactLabelError;
