import { Paper, PaperProps } from "@mui/material";

type CustomProps = {
  /**
   * value for custom margin bottom
   */
  marginBottom?: number;
};

/**
 *
 * @param param0 children : ReactNode
 * @returns a Paper wrapper component with predefined padding, height and shadow
 */

const CustomPaper = (props: PaperProps & CustomProps) => {
  return (
    <Paper
      // {...props}
      elevation={1}
      sx={{
        minHeight: "75vh",
        margin: { xs: `16px 16px ${props.marginBottom || 8}rem`, sm: "1rem", lg: "1rem 0 2rem" },
        padding: {
          xs: "20px 24px 10px ",
          sm: "1rem",
          lg: "40px 48px 40px 40px",
        },
        maxWidth: "98vw",
        borderRadius: { xs: "17px", md: "24px" },
        bgcolor: "white",
        boxShadow: "0px 1px 0px 1px rgb(140 140 140 / 15%)",
      }}
    >
      {props.children}
    </Paper>
  );
};

export default CustomPaper;
