import { Theme, createTheme, experimental_sx as sx } from "@mui/material";

const theme: Theme = createTheme({
  palette: {
    primary: {
      main: "#55B1F5",
    },
    info: {
      main: "#F9C846",
    },
    secondary: {
      main: "#E6F5FF",
    },

    error: {
      main: "#F85B5B",
    },
    neutral: {
      main: "#afafaf",
    },
    success: {
      main: "#2BA690",
    },
  },
  typography: {
    fontFamily: "Poppins",
    body1: {
      fontSize: 15,
      fontWeight: 500,
      letterSpacing: ".5px",
      lineHeight: "22.4px",
    },
    h1: {
      fontSize: 23,
      fontWeight: 600,
      letterSpacing: "0.0025em",
      "@media screen and (min-width: 768px)": {
        fontSize: 33,
        lineHeight: "130%",
      },
    },
    h2: {
      fontSize: 28,
      fontWeight: 600,
    },
    h3: {
      fontSize: 19,
      fontWeight: 600,
      // alternative to the sx syntax but prone to errors
      "@media screen and (min-width: 768px)": {
        fontSize: 24,
      },
    },
    h4: {
      fontSize: 16,
      fontWeight: 600,
      "@media screen and (min-width: 768px)": {
        fontSize: 20,
      },
    },
    h5: {
      fontSize: 16,
      fontWeight: 600,
    },
    button: {
      textTransform: "none",
      fontSize: 13,
      letterSpacing: "0.0125em",
    },
    caption: {
      fontSize: 13,
    },
  },

  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: sx({
          bgcolor: "rgba(67, 66, 66, 0.46)",
        }),
      },
    },
    MuiListItemText: {
      styleOverrides: sx({
        root: {
          color: "grey",
          fontSize: 15,
        },
      }),
    },
    MuiPaper: {
      styleOverrides: {
        root: sx({
          boxShadow: "1px 3px 4px rgba(140, 140, 140, 0.16)",
        }),
      },
    },

    MuiDialog: {
      styleOverrides: {
        paper: sx({
          borderRadius: "8px !important",
          mx: { xs: 0 },
          width: { xs: "95%" },
        }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: sx({
          fontSize: { xs: "20px", sm: "23px" },
          fontWeight: 700,
          textAlign: "center",
          pt: { xs: "40px", sm: "60px" },
          px: { xs: 2, sm: "40px" },
        }),
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: sx({
          pb: { xs: "40px", sm: "60px" },
          px: { xs: 2, sm: "40px" },
        }),
      },
    },

    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          color: "white",
        },
        root: sx({
          p: { xs: "18px 30px", sm: "16px 54px" },
          fontSize: 13,
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        colorSecondary: sx({
          color: "primary.main",
          // ":hover , :active": {
          //   backgroundColor: "primary.main",
          //   color: "white",
          // },
        }),
        colorPrimary: {
          color: "white",
        },

        root: sx({
          fontSize: 13,
          borderRadius: "4px",
          padding: "8px",
          height: "44px",
          fontWeight: 500,
          cursor: "pointer",
          "&.MuiChip-filledInfo": {
            color: "white",
          },
        }),
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: sx({
          borderRadius: "4px",
          ".MuiSnackbarContent-root": {
            padding: "18px 20px",
          },
          ".MuiSnackbarContent-message": {
            padding: 0,
          },
          ".MuiSnackbarContent-action": {
            padding: 0,
            mr: 0,
          },
        }),
      },
    },
  },
});

export default theme;

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }
  interface PaletteOptions {
    neutral: PaletteOptions["primary"];
  }
  // interface MuiChip {
  //   styleOverrides: {
  //     colorError: ChipClasses;
  //   };
  // }
}
