import {
  Grid,
  Typography,
  Stack,
  Chip,
  useMediaQuery,
  Theme,
  Divider,
} from "@mui/material";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { LightTooltip } from "../../";
import {
  useAppSelector,
  useAppDispatch,
  selectSettingContacts,
  selectSettingState,
  setContactsToGet,
} from "../../../appStore";

/**
 * Displays the contact number Component
 *
 * Allowing users to choose how many contacts they want to get per review
 */
const ContactNumberSettings = () => {
  const dispatch = useAppDispatch();

  const chosenNumber = useAppSelector(selectSettingState).contacts_to_get;
  const contactNumbers = useAppSelector(selectSettingContacts);

  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("md")
  );
  return (
    <Grid item md={6} xs={12} mt={{ sm: 4 }}>
      {isMobile ? <Divider sx={{ my: 3 }} light /> : null}
      <Typography variant="h5" sx={{ mb: "10px", maxWidth: "404px" }}>
        And how many contacts do you want to review each time?
        <LightTooltip
          title="Add tags to help you organize your network and recall people"
          sx={{
            maxWidth: "200px",
            padding: "12px",
          }}
          arrow
        >
          <InfoOutlined htmlColor="#363636" sx={{ mb: -0.8, ml: 1 }} />
        </LightTooltip>
      </Typography>

      <Stack direction="row" spacing={1}>
        {/* map contact numbers to chips */}
        {contactNumbers.map((item, index) => (
          <Chip
            key={index}
            label={item}
            color={item === chosenNumber ? "primary" : "secondary"}
            onClick={() => {
              dispatch(setContactsToGet(item));
            }}
          />
        ))}
      </Stack>
    </Grid>
  );
};

export default ContactNumberSettings;
