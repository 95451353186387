import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  Radio,
  ListItemText,
  Divider,
  Stack,
  Button,
  ListItemButton,
} from "@mui/material";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Add from "@mui/icons-material/Add";
import Close from "@mui/icons-material/Close";
import { MastercardLogo, VisacardLogo } from "../../assets/icons";
import { ResponsiveButtonGroup } from "..";
import {
  useAppSelector,
  useAppDispatch,
  closeDialog,
  openAddPaymentMethodDialog,
  openDeletePaymentMethodDialog,
} from "../../appStore";
import { useNavigate } from "react-router";
import { PaymentMethodProps } from "../../utils";

/**
 * @returns Dialog to add a payment method if there are no stored cards, else a list of cards
 * @todo Add redux implementation that checks if there are cards and updates the component UI
 */
const ManagePaymentMethodDialog = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const openState = useAppSelector((state) => state.dialog.managePaymentMethod);
  const hasCard = false;
  const cards: PaymentMethodProps[] = [];
  const close = () => {
    dispatch(closeDialog());
  };
  const chooseCardIcon = (cardNumber: string) => {
    if (Number(cardNumber.charAt(0)) === 5) return <MastercardLogo />;
    if (Number(cardNumber.charAt(0)) === 4) return <VisacardLogo />;
  };
  const chooseCardName = (cardNumber: string) => {
    let paddedLast4Digits = cardNumber.slice(-4).padStart(7, ".");

    if (Number(cardNumber.charAt(0)) === 5)
      return `Mastercard ${paddedLast4Digits}`;
    if (Number(cardNumber.charAt(0)) === 4) return `Visa ${paddedLast4Digits}`;
  };

  return (
    <Dialog
      open={openState}
      sx={{ textAlign: "center" }}
      onClose={close}
      maxWidth="xs"
    >
      <Close
        style={{
          position: "absolute",
          top: 24,
          right: 24,
          color: "#222628",
        }}
        onClick={close}
      />
      <DialogTitle sx={{ px: "60px" }}>Manage payment method</DialogTitle>

      {hasCard ? (
        /**
         * Dialog content to display when there is at least one payment method
         *  */

        <DialogContent sx={{ px: hasCard ? 0 : "64px !important" }}>
          <List
            sx={{
              mb: 2,
              ml: { sm: "14px" },
              ".MuiListItemButton-root": {
                px: 0,
              },
              ".MuiRadio-root": {
                px: 0,
              },
            }}
          >
            {cards.map((item, index) => (
              <ListItem
                key={index}
                secondaryAction={
                  <DeleteOutline
                    className="faded"
                    onClick={() => {
                      dispatch(openDeletePaymentMethodDialog());
                    }}
                  />
                }
                disableGutters
              >
                <ListItemIcon>
                  <Radio checked></Radio>
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Stack direction="row" spacing={1}>
                      <>
                        {chooseCardIcon(item.cardNumber)}
                        <span>
                          {chooseCardName(item.cardNumber)} ({item.expiryDate})
                        </span>
                      </>
                    </Stack>
                  }
                ></ListItemText>
              </ListItem>
            ))}
            <Divider />
            <ListItem disableGutters>
              <ListItemButton
                onClick={() => {
                  dispatch(openAddPaymentMethodDialog());
                }}
              >
                <ListItemIcon>
                  <Add className="faded" />
                </ListItemIcon>
                <ListItemText
                  primary="Add new"
                  className="faded"
                ></ListItemText>
              </ListItemButton>
            </ListItem>
          </List>

          <ResponsiveButtonGroup
            button1={
              <Button
                variant="outlined"
                className="custom-outlined"
                onClick={() => dispatch(closeDialog())}
              >
                Cancel
              </Button>
            }
            button2={
              <Button
                variant="contained"
                onClick={() => {
                  dispatch(closeDialog());
                  navigate("/subscription-success");
                }}
              >
                Continue
              </Button>
            }
          />
        </DialogContent>
      ) : (
        //  Dialog content to display when there are no payment methods

        <DialogContent sx={{ px: "84px !important" }}>
          <Typography sx={{ mb: 3 }} fontSize={13}>
            You haven’t got any payment method. Add your payment method
          </Typography>
          <Button
            fullWidth
            variant="contained"
            onClick={() => dispatch(openAddPaymentMethodDialog())}
          >
            Add card
          </Button>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default ManagePaymentMethodDialog;
