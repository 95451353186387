import { forwardRef } from "react";
import { SvgIconProps } from "@mui/material";
import ThumbUpAltOutlined from "@mui/icons-material/ThumbUpAltOutlined";
// import ThumbUp from "@mui/icons-material/ThumbUp";

const Star = forwardRef<SVGSVGElement, SvgIconProps>(
  ({ ...restofProps }, ref) => {
    // const [hoverState, setHoverState] = useState<boolean>(false);
    return (
      <span
        style={{
          display: "grid",
          placeItems: "center",
          fontSize: "26px",
        }}
        // onMouseOver={() => {
        //   setHoverState(true);
        // }}
        // onMouseLeave={() => {
        //   setHoverState(false);
        // }}
      >
        <ThumbUpAltOutlined
          ref={ref}
          color="success"
          tabIndex={1}
          {...restofProps}
        />
      </span>
    );
  }
);

export default Star;
