import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createSelector,
} from "@reduxjs/toolkit";
import { AXIOS_CLIENT, RootState } from "../..";
import {
  SettingContacts,
  SettingFrequencies,
  SettingsType,
} from "../../../utils";

const initialState: SettingsType = {
  setting: {
    frequency: "daily",
    custom_frequency: 0,
    contacts_to_get: 5,
    is_custom_frequency: false,
    sync_labels: true,
    subscribed: false,
  },
  meta: {
    contacts_to_get: [1, 5, 10, 15],
    frequencies: ["daily", "weekdays", "weekends", "weekly"],
  },
  settingUpdateStatus: false,
};

/*******************THUNKS************************/

/**
 * Fetch user setings
 */

export const fetchSettings = createAsyncThunk("settings/get", async () => {
  const response = await AXIOS_CLIENT.get("setting");
  return response.data;
});

export const updateSettings = createAsyncThunk(
  "settings/update",
  async (showAlert: boolean, { getState }) => {
    const state = getState() as RootState;
    const response = await AXIOS_CLIENT.put("setting", state.settings.setting);

    return { data: response.data, alert: showAlert };
  }
);

/*******************SLICE*********************/

/**
 *  settings slice, actions and handle fetched data
 */
const settingSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setContactsToGet: (state, action: PayloadAction<SettingContacts>) => {
      state.setting.contacts_to_get = action.payload;
    },
    setFrequency: (
      state,
      action: PayloadAction<SettingFrequencies | number>
    ) => {
      if (typeof action.payload === "number") {
        state.setting.custom_frequency = action.payload;
      } else {
        state.setting.is_custom_frequency = false;
        state.setting.frequency = action.payload;
      }
    },
    setHowToSaveTags: (state, action: PayloadAction<boolean>) => {
      state.setting.sync_labels = action.payload;
    },
    toggleCustomFrequency: (
      state,
      action?: PayloadAction<boolean | undefined>
    ) => {
      if (
        state.setting.custom_frequency > 0 &&
        state.setting.custom_frequency <= 30
      ) {
        state.setting.is_custom_frequency =
          action?.payload || !state.setting.is_custom_frequency;
      }
    },
    toggleSettingUpdateStatus: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.settingUpdateStatus = action?.payload || !state.settingUpdateStatus;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSettings.fulfilled, (state, action) => {
        state = { ...state, ...action.payload };
        return state;
      })
      .addCase(updateSettings.fulfilled, (state, action) => {
        state = { ...state, ...action.payload };
        state.settingUpdateStatus = action.payload.alert;
        return state;
      });
  },
});

/********************SELECTORS*********************/

/**
 * select the entire settings state
 */
export const selectSettingState = createSelector(
  [(state: RootState) => state.settings.setting],
  (setting) => setting
);
export const selectSettingContacts = createSelector(
  [(state: RootState) => state.settings.meta.contacts_to_get],
  (contacts) => contacts
);
export const selectSettingFrequencies = createSelector(
  [(state: RootState) => state.settings.meta.frequencies],
  (frequency) => frequency
);

export const {
  setContactsToGet,
  setFrequency,
  toggleCustomFrequency,
  toggleSettingUpdateStatus,
  setHowToSaveTags,
} = settingSlice.actions;

export default settingSlice.reducer;
