import React, { useEffect, useState } from "react";
import {
  useAppSelector,
  closeDialog,
  useAppDispatch,
  openPaymentMethodAddedDialog,
} from "../../appStore";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import { PaymentMethodProps } from "../../utils";
import Close from "@mui/icons-material/Close";
import CustomCheckbox from "../CustomCheckbox";

const initialState = {
  name: "",
  cvv: "",
  expiryDate: "",
  cardNumber: "",
};
/**
 *
 * @returns Payment form/checkout form in a fullscreen dialog
 */
const AddPaymentMethodDialog = () => {
  const [formState, setFormState] = useState<PaymentMethodProps>(initialState);

  const dispatch = useAppDispatch();

  const openState = useAppSelector((state) => state.dialog.addPaymentMethod);

  useEffect(() => {
    return () => {
      setFormState(initialState);
    };
  }, []);
  const close = () => {
    dispatch(closeDialog());
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  const submitPaymentCard = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    //temporary validation
    if (formState.cardNumber.length && formState.expiryDate.length) {
      dispatch(openPaymentMethodAddedDialog());
    }
  };

  return (
    <Dialog open={openState} onClose={close} maxWidth="xs">
      <Close
        style={{
          position: "absolute",
          top: 36,
          right: 36,
          color: "#222628",
        }}
        onClick={close}
      />
      <DialogTitle gutterBottom fontSize={23}>
        Add payment method
      </DialogTitle>
      <DialogContent
        sx={{
          mt: 1,
          px: "40px",
          input: {
            borderRadius: "8px",
          },
        }}
      >
        <form
          style={{ width: "386px", maxWidth: "100%" }}
          onSubmit={submitPaymentCard}
        >
          <div>
            <label htmlFor="card number">Number</label>
            <input
              type="number"
              placeholder="Type here..."
              name="cardNumber"
              value={formState.cardNumber}
              onChange={onChange}
            />
          </div>

          <Stack direction="row" spacing={3}>
            <div>
              <label htmlFor="expiry date">Expires date</label>
              <input
                type="text"
                placeholder="12/24"
                name="expiryDate"
                value={formState.expiryDate}
                onChange={onChange}
                onKeyUp={() => {
                  if (
                    formState.expiryDate.length === 2 &&
                    !isNaN(Number(formState.expiryDate))
                  )
                    setFormState((prev) => ({
                      ...prev,
                      expiryDate: prev.expiryDate + "/",
                    }));
                }}
              />
            </div>
            <div>
              <label htmlFor="cvv">CVV code</label>
              <input
                type="number"
                placeholder="Type here..."
                name="cvv"
                value={formState.cvv}
                onChange={onChange}
              />
            </div>
          </Stack>

          <div>
            <label htmlFor="card holder's name">Name on card</label>
            <input
              type="text"
              placeholder="Type here..."
              name="name"
              value={formState.name}
              onChange={onChange}
            />
          </div>
          <div>
            <CustomCheckbox
              label={
                <Typography
                  color="var(--primary)"
                  display="inline-block"
                  variant="body2"
                >
                  Save card
                </Typography>
              }
            />
          </div>
          <Button fullWidth variant="contained" sx={{ mt: 2 }} type="submit">
            Add card
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddPaymentMethodDialog;
