import { useNavigate } from "react-router";
import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import { ResponsiveButtonGroup } from "..";
import {
  useAppDispatch,
  useAppSelector,
  closeDialog,
  signout,
} from "../../appStore";

const DeleteContactDialog = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const openState = useAppSelector((state) => state.dialog.signoutConfirmation);
  const close = () => {
    dispatch(closeDialog());
  };

  const signoutFunc = () => {
    dispatch(closeDialog());
    dispatch(signout());
    navigate("/");
  };
  return (
    <Dialog
      open={openState}
      onClose={close}
      maxWidth="md"
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          signoutFunc();
        }
      }}
    >
      <DialogTitle gutterBottom sx={{ px: "54px", mb: 3 }}>
        Are you sure you want to sign out?
      </DialogTitle>
      <DialogContent>
        <ResponsiveButtonGroup
          reorder
          button1={
            <Button
              variant="outlined"
              size="large"
              className="custom-outlined"
              onClick={() => {
                dispatch(closeDialog());
              }}
            >
              Cancel
            </Button>
          }
          button2={
            <Button
              variant="contained"
              color="error"
              size="large"
              onClick={signoutFunc}
            >
              Sign out
            </Button>
          }
        />
      </DialogContent>
    </Dialog>
  );
};

export default DeleteContactDialog;
