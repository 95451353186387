import { createAsyncThunk } from "@reduxjs/toolkit";
import { AXIOS_CLIENT } from "../../";

/**
 * Get all statistcs
 */

export const fetchStatistics = createAsyncThunk(
  "statistics/fetch_stats",
  async () => {
    const response = await AXIOS_CLIENT.get("stats");
    return response.data;
  }
);

export const incrementMailingStat = createAsyncThunk(
  "statictics/increment_mailing",
  async () => {
    const response = await AXIOS_CLIENT.put("stats/increment_mail");
    return response.data;
  }
);
