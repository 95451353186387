import { useAppSelector } from "../../appStore";
import { ErrorLayout } from "..";

/**
 * @returns Dialog to display error when the add contact label action fails
 */
const AddContactLabelError = () => {
  const openState = useAppSelector(
    (state) => state.errors.addContactLabelError
  );
  // const errorMessage = useAppSelector(
  //   (state) => state.actions.present.errorMessage
  // );

  return (
    <ErrorLayout
      openState={openState}
      title={"The contact you're trying to edit no longer exists on your Google Contacts account."}
      button2Text="Continue"
    />
  );
};

export default AddContactLabelError;
