import { useState } from "react";
import { NavLink } from "react-router-dom";
import Menu from "@mui/icons-material/Menu";
import {
  Box,
  Stack,
  Avatar,
  useMediaQuery,
  Theme,
  Backdrop,
  Typography,
} from "@mui/material";
import { FooterLinks, SignOut } from "../";
import { useAppSelector, selectUsername, selectUserData } from "../../appStore";
/**
 * Displays the navigation menu, fully optimized for mobile
 */
const NavMenu = () => {
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));
  const [isNavOpen, setNavOpen] = useState(false);

  const toggleNav = () => {
    setNavOpen((prev) => !prev);
  };

  return isDesktop ? (
    <Box className="desktop nav">
      <MenuLinks />
    </Box>
  ) : isNavOpen ? (
    <Backdrop open={isNavOpen} onClick={toggleNav} sx={{ zIndex: 20 }}>
      <Box className="mobile open nav">
        <MenuLinks mobile />
      </Box>
    </Backdrop>
  ) : (
    <Menu fontSize="large" htmlColor="#323131" onClick={toggleNav} />
  );
};

const MenuLinks = ({ mobile = false }: { mobile?: boolean }) => {
  const username = useAppSelector(selectUsername);
  const userData = useAppSelector(selectUserData);

  return (
    <>
      {mobile ? (
        <Stack direction="row" alignItems="center" mb={2}>
          <Avatar
            sx={{ width: 64, height: 64, mr: 2 }}
            src={userData.image_url}
            imgProps={{
              referrerPolicy: "no-referrer",
            }}
          />
          <Typography fontSize={17} fontWeight={600}>
            Hello {username}
          </Typography>
        </Stack>
      ) : null}
      <NavLink
        to="/"
        tabIndex={-1}
        className={({ isActive }) => (isActive ? "active-navlink" : "")}
      >
        Review contacts
      </NavLink>

      <a href="https://contacts.google.com/" target="_blank" tabIndex={-1} rel="noreferrer">
        Open Google Contacts
      </a>
      <NavLink
        to="/settings"
        tabIndex={-1}
        className={({ isActive }) => (isActive ? "active-navlink" : "")}
      >
        Settings
      </NavLink>
      <NavLink
        to="/stats"
        tabIndex={-1}
        className={({ isActive }) => (isActive ? "active-navlink" : "")}
      >
        My stats
      </NavLink>
      {mobile ? (
        <>
          <Box mt={3}>
            <SignOut />
          </Box>
          <Box sx={{ marginTop: "auto" }}>
            <FooterLinks />
          </Box>
        </>
      ) : (
        <Avatar
          sx={{ width: 40, height: 40 }}
          src={userData.image_url}
          imgProps={{
            referrerPolicy: "no-referrer",
          }}
        />
      )}
    </>
  );
};

export default NavMenu;
