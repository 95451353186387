import { lazy, Suspense } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import {
  selectLoggedInStatus,
  useAppSelector,
  selectIsSubscribed,
} from "./appStore";
import {
  ContactDeletedDialog,
  SubscriptionPromptDialog,
  DeletePaymentMethodDialog,
  AddPaymentMethodDialog,
  PaymentMethodAddedDialog,
  ManagePaymentDialog,
  UpgradePlanDialog,
  SignoutConfirmationDialog,
  HotKeyList,
  ToggleStarError,
  ReviewError,
  SnoozeError,
  DeleteContactError,
  ConnectionLostError,
  UpdateSettingsError,
  AddContactEventsError,
  AddContactLabelError,
  DeleteContactEventError,
  DeleteContactLabelError,
  UpdateContactNotesError,
  OlderDigestDialog,
} from "./components";
const Login = lazy(() => import("./pages/Login/index"));
const Setup = lazy(() => import("./pages/Setup"));
const Plans = lazy(() => import("./pages/Plans"));
const Action = lazy(() => import("./pages/Action"));
const Settings = lazy(() => import("./pages/Settings"));
const SubmitContacts = lazy(() => import("./pages/SubmitContacts"));
const Gamification = lazy(() => import("./pages/Gamification"));
const ContactSnoozed = lazy(() => import("./pages/Notices/ContactSnoozed"));
const ProcessPayment = lazy(() => import("./pages/processes/ProcessPayment"));
const ProcessRedirect = lazy(() => import("./pages/processes/ProcessRedirect"));
const ActivationError = lazy(() => import("./pages/Notices/ActivationError"));
const SubscriptionSuccess = lazy(
  () => import("./pages/Notices/SubscriptionSuccess")
);

/**
 *
 *@todo Add Tab index attr on all focusable elements for accessibility
 */

function App(): JSX.Element {
  const loggedIn = useAppSelector(selectLoggedInStatus);
  const isSubscribed = useAppSelector(selectIsSubscribed);
  return (
    <div>
      <BrowserRouter basename="/">
        {/* routes */}
        <Suspense>
          <Routes>
            {loggedIn && !isSubscribed ? (
              <Route path="/" element={<Plans />} />
            ) : isSubscribed ? (
              <Route path="/" element={<Action />} />
            ) : (
              <Route path="/" element={<Login />} />
            )}

            <Route
              path="/:id"
              element={
                <>{isSubscribed ? <Action /> : <Navigate to={"/login"} />}</>
              }
            />
            <Route
              path="/digest"
              element={
                <>{isSubscribed ? <Action /> : <Navigate to={"/login"} />}</>
              }
            >
              <Route path="*" element={<Action />} />
            </Route>

            <Route path="setup" element={<Setup />} />

            {isSubscribed ? (
              <>
                <Route path="stats" element={<Gamification />} />
                <Route path="settings" element={<Settings />} />
                <Route path="contacts" element={<Action />} />
              </>
            ) : null}
            <Route path="login" element={<Login />} />

            <Route path="redirect" element={<ProcessRedirect />} />
            <Route path="plans" element={<Plans />} />
            <Route path="snoozed" element={<ContactSnoozed />} />
            <Route path="submit-contacts" element={<SubmitContacts />} />
            <Route
              path="subscription-error"
              element={<ActivationError />}
            ></Route>
            <Route
              path="subscription-complete"
              element={<SubscriptionSuccess />}
            ></Route>
            <Route
              path="subscription-success/*"
              element={<ProcessPayment />}
            ></Route>
          </Routes>
        </Suspense>

        {/* hot keys component */}
        <HotKeyList />

        {/* dialogs */}
        <SubscriptionPromptDialog />
        <ContactDeletedDialog />
        <DeletePaymentMethodDialog />
        <AddPaymentMethodDialog />
        <PaymentMethodAddedDialog />
        <ManagePaymentDialog />
        <UpgradePlanDialog />
        <SignoutConfirmationDialog />
        <OlderDigestDialog />

        {/* errors */}
        <ToggleStarError />
        <ReviewError />
        <SnoozeError />
        <DeleteContactError />
        <ConnectionLostError />
        <UpdateSettingsError />
        <AddContactEventsError />
        <AddContactLabelError />
        <DeleteContactEventError />
        <DeleteContactLabelError />
        <UpdateContactNotesError />
      </BrowserRouter>
    </div>
  );
}

export default App;
