function falsifyObject<Type extends object>(obj: Type): Type {
  let newObj = { ...obj };

  for (const key in newObj) {
    if (newObj.hasOwnProperty(key)) {
      if (typeof newObj[key] === "boolean") {
        newObj[key] = false as unknown as Type[typeof key];
      }
    }
  }

  return newObj;
}

export default falsifyObject;
