import { createSlice } from "@reduxjs/toolkit";
import { falsifyObject, ErrorSliceType } from "../../../utils";
import {
  toggleContactStarState,
  deleteContact,
  deleteContactEvents,
  deleteContactLabel,
  updateContactNotes,
  reviewContact,
  addContactEvents,
  addTagToContacts,
  snoozeContact,
  updateSettings,
  login,
  authorize,
} from "../../";

const initialState: ErrorSliceType = {
  toggleStarError: false,
  addContactLabelError: false,
  deleteContactLabelError: false,
  updateContactNotesError: false,
  addContactEventsError: false,
  deleteContactEventError: false,
  deleteContactError: false,
  snoozeError: false,
  reviewError: false,
  updateSettingsError: false,
  connectionLostError: false,
  loginError: false,
  authorizeError: false,
};

const openError = (name: keyof ErrorSliceType, state: ErrorSliceType) => {
  state = falsifyObject(state);
  if (typeof state[name] === "boolean") {
    state = { ...state, [name]: true };
  }
  return state;
};

const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    closeError: (state) => {
      state = falsifyObject(state);
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(toggleContactStarState.rejected, (state) => {
        return openError("toggleStarError", state);
      })
      .addCase(deleteContact.rejected, (state) => {
        return openError("deleteContactError", state);
      })
      .addCase(deleteContactEvents.rejected, (state) => {
        return openError("deleteContactEventError", state);
      })
      .addCase(deleteContactLabel.rejected, (state) => {
        return openError("deleteContactLabelError", state);
      })
      .addCase(updateContactNotes.rejected, (state) => {
        return openError("updateContactNotesError", state);
      })
      .addCase(reviewContact.rejected, (state) => {
        return openError("reviewError", state);
      })
      .addCase(addContactEvents.rejected, (state) => {
        return openError("addContactEventsError", state);
      })
      .addCase(addTagToContacts.rejected, (state) => {
        return openError("addContactLabelError", state);
      })
      .addCase(snoozeContact.rejected, (state) => {
        return openError("snoozeError", state);
      })
      .addCase(updateSettings.rejected, (state) => {
        return openError("updateSettingsError", state);
      })
      .addCase(login.rejected, (state) => {
        return openError("loginError", state);
      })
      .addCase(authorize.rejected, (state) => {
        return openError("authorizeError", state);
      });
  },
});

export const { closeError } = errorSlice.actions;

export default errorSlice.reducer;
