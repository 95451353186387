import { useAppSelector } from "../../appStore";
import { ErrorLayout } from "..";

/**
 * @returns Dialog to display error when the snooze action fails
 */
const SnoozeError = () => {
  const openState = useAppSelector((state) => state.errors.snoozeError);

  return (
    <ErrorLayout
      openState={openState}
      title="Can’t snooze contact"
      description="Please refresh the page to try snoozing this contact again"
    />
  );
};

export default SnoozeError;
