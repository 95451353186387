import { Box, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";
import { SingleDigest } from "../../";
import { StatisticsType, ReviewStreakType } from "../../../utils";
import { useAppSelector, selectPendingReviewStreak } from "../../../appStore";

const ReviewStreaks = ({ data }: { data: StatisticsType }) => {
  const isPendingReview = useAppSelector(selectPendingReviewStreak);

  return (
    <Box>
      <Typography variant="h4" lineHeight="34px" fontSize={19}>
        Your best streak: {data.best_streak} digests
      </Typography>
      <Typography
        variant="h4"
        mb={{ xs: "24px", sm: "30px" }}
        lineHeight="34px"
        fontSize={19}
      >
        Your current streak: {data.current_streak} digests
      </Typography>
      <Typography
        variant="h4"
        mb={{ xs: "14px", sm: 2 }}
        lineHeight="34px"
        fontSize={19}
      >
        Your last 20 digests
      </Typography>
      <Stack
        sx={{
          textAlign: "center",
          width: { xs: "352px", sm: "400px" },
          maxWidth: "100%",

          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(auto-fill, 28px)",
            sm: "repeat(10, 32px)",
          },
          columnGap: { xs: "6px", sm: "8px" },
          rowGap: { xs: "6px", sm: "12px" },
        }}
      >
        {Array.from(
          { ...data.review_streaks, length: 20 },
          (a) =>
            a ?? {
              day: "Monday",
              status: "" as ReviewStreakType["status"],
            }
        )?.map((item) => (
          <SingleDigest status={item.status} />
        ))}
      </Stack>
      <Stack direction="row" spacing={{ xs: 0.8, sm: 2 }} mt={3}>
        <Summary status="missed">
          {data.missed} missed review{data.missed > 1 ? "s" : ""}
        </Summary>
        <Summary status="partial">
          {data.partially_reviewed} partial review
          {data.partially_reviewed > 1 ? "s" : ""}
        </Summary>
        {isPendingReview ? (
          <Summary status="pending">1 pending review</Summary>
        ) : null}
        <Summary status="full">
          {data.fully_reviewed} full review{data.fully_reviewed > 1 ? "s" : ""}
        </Summary>
      </Stack>
    </Box>
  );
};

export default ReviewStreaks;

const Summary = (props: {
  // temporary implementation : should be number
  children: ReactNode;
  status: "missed" | "partial" | "full" | "pending";
}) => {
  const color =
    props.status === "missed"
      ? "error.main"
      : props.status === "partial"
      ? "info.main"
      : props.status === "pending"
      ? "#A9BCD0"
      : "success.main";

  return (
    <Stack direction="row" alignContent="center" alignItems="center">
      <Box
        sx={{
          width: 10,
          height: 10,
          borderRadius: "2px",
          mr: { xs: 0.5, sm: 1 },
          bgcolor: color,
        }}
      ></Box>
      <Typography sx={{ fontSize: { xs: 10, sm: 12 }, color: "#727272" }}>
        {props.children}
      </Typography>
    </Stack>
  );
};
