import { createAsyncThunk } from "@reduxjs/toolkit";
import { AXIOS_CLIENT, RootState, store } from "../../..";
import { EventInputType } from "../../../../utils";
import { AxiosError } from "axios";

/**
 * @todo all actions that apply to the contact under review, will return an object with data prop - data returned from request * and id prop- id of contact under review
 * */

/**
 * Function to retrieve the ids of the contact under review and the digest
 */

const getDigestId = (getState: () => unknown): number => {
  const state = getState() as RootState;
  return state.actions.present.id;
};
const getPast = (getState: () => unknown) => {
  const state = getState() as RootState;
  return state.actions.past;
};

/*******************THUNKS*******************/

/**
 * Fetches the active digest
 */
export const fetchActiveDigest = createAsyncThunk(
  "actions/digest_fetch",
  async () => {
    const response = await AXIOS_CLIENT.get("digests/active");
    return response.data;
  }
);

/**
 * Fetches a specific digest with its id
 */
export const fetchSpecificDigest = createAsyncThunk(
  "actions/digest_fetch_specific",
  async (id: number) => {
    const response = await AXIOS_CLIENT.get(`digests/${id}`);
    return response.data;
  }
);

/**
 * Generates a new digest
 */
export const generateNewDigest = createAsyncThunk(
  "actions/digest_generate_new",
  async () => {
    const response = await AXIOS_CLIENT.put("digests/generate");
    return response.data;
  }
);

/**
 * Toggle the starred state of the contact
 */
export const toggleContactStarState = createAsyncThunk(
  "actions/contacts_star",
  async (id: number, { getState, rejectWithValue }) => {
    let digestId = getDigestId(getState);
    let past = getPast(getState);

    try {
      const response = await AXIOS_CLIENT.put(
        `digests/${digestId}/contacts/${id}/toggle_starred`
      );
      return response.data;
    } catch (err: any) {
      let error: AxiosError = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(past[past.length - 1]);
    }
  }
);

/**
 *  alias - assign_contacts_label
 */
export const addTagToContacts = createAsyncThunk(
  "actions/contacts_addTag",
  async (
    { id, name }: { id: number; name: string },
    { getState, rejectWithValue }
  ) => {
    let digestId = getDigestId(getState);
    let past = getPast(getState);
    try {
      const response = await AXIOS_CLIENT.post(
        `digests/${digestId}/contacts/${id}/assign_label`,
        {
          name,
        }
      );

      return response.data;
    } catch (err: any) {
      let error: AxiosError = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(past[past.length - 1]);
    }
  }
);

/**
 * Delete a tag/label from a specific contact. If no contact has the label, it deletes it entirely
 */
export const deleteContactLabel = createAsyncThunk(
  "actions/contacts_deleteTag",
  async (
    { contactId, labelId }: { contactId: number; labelId: number },
    { getState, rejectWithValue }
  ) => {
    let digestId = getDigestId(getState);
    let past = getPast(getState);
    try {
      const response = await AXIOS_CLIENT.delete(
        `digests/${digestId}/contacts/${contactId}}/remove_label?label_id=${labelId}`
      );

      return { data: response.data, id: contactId };
    } catch (err: any) {
      let error: AxiosError = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(past[past.length - 1]);
    }
  }
);

/**
 * Update a contact's notes
 */
export const updateContactNotes = createAsyncThunk(
  "actions/contacts_updateNotes",
  async (id: number, { getState, rejectWithValue }) => {
    try {
      let digestId = getDigestId(getState);
      let notes =
        store.getState().actions.present.contacts.find((item) => item.id === id)
          ?.notes || "";

      const response = await AXIOS_CLIENT.put(
        `digests/${digestId}/contacts/${id}/update_notes`,
        { notes }
      );

      return response.data;
    } catch (err) {
      throw err;
    }
  }
);

/**
 * Add a new event to a contact
 */
export const addContactEvents = createAsyncThunk(
  "actions/contacts_addEvent",
  async (
    { id, event }: { id: number; event: EventInputType },
    { getState, rejectWithValue }
  ) => {
    let digestId = getDigestId(getState);
    let past = getPast(getState);
    try {
      const response = await AXIOS_CLIENT.post(
        `digests/${digestId}/contacts/${id}/add_event`,
        event
      );

      return response.data;
    } catch (err: any) {
      let error: AxiosError = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(past[past.length - 1]);
    }
  }
);

/**
 * delete an event for a contact
 */
export const deleteContactEvents = createAsyncThunk(
  "actions/contacts_deleteEvent",
  async (
    { id, eventId }: { id: number; eventId: number },
    { getState, rejectWithValue }
  ) => {
    let digestId = getDigestId(getState);
    let past = getPast(getState);
    try {
      const response = await AXIOS_CLIENT.delete(
        `digests/${digestId}/contacts/${id}/remove_event?event_id=${eventId}`
      );

      return { data: response.data, id };
    } catch (err: any) {
      let error: AxiosError = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(past[past.length - 1]);
    }
  }
);

/**
 * deletes the  contact
 */
export const deleteContact = createAsyncThunk(
  "actions/contacts_delete",
  async (id: number, { getState }) => {
    let digestId = getDigestId(getState);
    const response = await AXIOS_CLIENT.delete(
      `digests/${digestId}/contacts/${id}/`
    );

    return response.data;
  }
);

/**
 * snooze a contact
 */

export const snoozeContact = createAsyncThunk(
  "actions/contacts_snooze",
  async (id: number, { getState }) => {
    let digestId = getDigestId(getState);
    const response = await AXIOS_CLIENT.put(
      `digests/${digestId}/contacts/${id}/snooze`
    );

    return response.data;
  }
);

/**
 * mark the contact as reviewed
 */

export const reviewContact = createAsyncThunk(
  "actions/contacts_review",
  async (id: number, { getState }) => {
    let digestId = getDigestId(getState);
    const response = await AXIOS_CLIENT.put(
      `digests/${digestId}/contacts/${id}/review`
    );

    return response.data;
  }
);
