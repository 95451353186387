import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type {
  DigestsType,
  EventObjectType,
  LabelObjectType,
  Option,
} from "../../../../utils";
import {
  toggleContactStarState,
  deleteContact,
  deleteContactEvents,
  deleteContactLabel,
  updateContactNotes,
  reviewContact,
  addContactEvents,
  addTagToContacts,
  fetchActiveDigest,
  snoozeContact,
  generateNewDigest,
  fetchSpecificDigest,
} from "./thunks";

const initialState: DigestsType = {
  id: 0,
  name: "",
  status: "pending",
  contacts: [],
  activeDigestId: null,
  emailDigestId: null,
  reviewContactLoading: false,
  deleteContactLoading: false,
  activeDigestLoading: false,
};

let id = -1;

const toLabelObjectType = (obj: Option): LabelObjectType => {
  id--;
  return { name: obj.value, id, is_google: false };
};

const actionsSlice = createSlice({
  name: "actions",
  initialState,
  reducers: {
    setContactNote: (
      state,
      action: PayloadAction<{ id: number; note: string }>
    ) => {
      if (state.contacts) {
        let index = state.contacts.findIndex(
          (contact) => contact.id === action.payload.id
        );

        if (index !== -1) state.contacts[index].notes = action.payload.note;
      }
    },
    setContactTags: (
      state,
      action: PayloadAction<{ id: number; tag: Option }>
    ) => {
      if (state.contacts) {
        let index = state.contacts.findIndex(
          (contact) => contact.id === action.payload.id
        );

        if (index !== -1)
          state.contacts[index].labels.push(
            toLabelObjectType(action.payload.tag)
          );
      }
    },
    removeContactTags: (
      state,
      action: PayloadAction<{ id: number; tag: LabelObjectType }>
    ) => {
      if (state.contacts) {
        let index = state.contacts.findIndex(
          (contact) => contact.id === action.payload.id
        );

        if (index !== -1)
          state.contacts[index].labels = state.contacts[index].labels.filter(
            (label) => label.name !== action.payload.tag.name
          );
      }
      return state;
    },
    setContactEvents: (
      state,
      action: PayloadAction<{ id: number; event: EventObjectType }>
    ) => {
      if (state.contacts) {
        let index = state.contacts.findIndex(
          (contact) => contact.id === action.payload.id
        );

        if (index !== -1)
          state.contacts[index].google_events.push(action.payload.event);
      }
      return state;
    },

    removeContactEvents: (
      state,
      action: PayloadAction<{ id: number; eventId: number }>
    ) => {
      if (state.contacts) {
        let index = state.contacts.findIndex(
          (contact) => contact.id === action.payload.id
        );
        if (index !== -1)
          state.contacts[index].google_events = state.contacts[
            index
          ].google_events.filter(
            (event) => event.id !== action.payload.eventId
          );
      }
      return state;
    },

    setContactStar: (state, action: PayloadAction<number>) => {
      if (state.contacts) {
        let index = state.contacts.findIndex(
          (contact) => contact.id === action.payload
        );

        if (index !== -1)
          state.contacts[index].starred = !state.contacts[index].starred;
      }
    },

    setDeleteContactLoading: (state) => {
      if (state.deleteContactLoading) {
        state.deleteContactLoading = false;
      }
    },
  
    setEmailDigestId: (state, action: PayloadAction<number | null>) => {
      state.emailDigestId = action.payload
    },
  
    refreshDeleteState: (state) => {
      if (state.tempDeleteContactsState) {
        state.contacts = state.tempDeleteContactsState
      }
    },
  
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload
    },
  
    setCurrentContactId: (state, action: PayloadAction<number>) => {
      state.currentContactId = action.payload
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchActiveDigest.fulfilled, (state, action) => {
        state = action.payload;
        state.activeDigestId = action.payload.id;
        state.activeDigestLoading = false;
        return state;
      })

      .addCase(fetchActiveDigest.pending, (state, action) => {
        state.activeDigestLoading = true;
        return state;
      })
      
      .addCase(fetchSpecificDigest.fulfilled, (state, action) => {
        state = action.payload;
        return state;
      })

      .addCase(generateNewDigest.fulfilled, (state, action) => {
        state = action.payload;
        return state;
      })

      .addCase(toggleContactStarState.fulfilled, (state, action) => {
        state = action.payload;
        return state;
      })
      .addCase(deleteContact.pending, (state, action) => {
        state.deleteContactLoading = true;
        return state;
      })
      .addCase(deleteContact.fulfilled, (state, action) => {
        state.tempDeleteContactsState = action.payload.contacts;
        state.deleteContactLoading = false;
        return state;
      })
      .addCase(deleteContactEvents.fulfilled, (state, action) => {
        state = action.payload.data;
        return state;
      })
      .addCase(deleteContactLabel.fulfilled, (state, action) => {
        state.contacts = state.contacts.map((item) => {
          return item.id === action.payload.id ? action.payload.data : item;
        });

        return state;
      })
      .addCase(updateContactNotes.fulfilled, (state, action) => {
        state = action.payload;
        return state;
      })
      .addCase(reviewContact.pending, (state, action) => {
        state.reviewContactLoading = true;
        return state;
      })
      .addCase(reviewContact.fulfilled, (state, action) => {
        state = action.payload;
        state.reviewContactLoading = false;
        return state;
      })
      .addCase(snoozeContact.fulfilled, (state, action) => {
        state = action.payload;
        return state;
      })
      .addCase(addContactEvents.fulfilled, (state, action) => {
        state = action.payload;
        return state;
      })
      .addCase(addTagToContacts.fulfilled, (state, action) => {
        state = action.payload;
        return state;
      })
      .addCase(toggleContactStarState.rejected, (state, action) => {
        state = action.payload as any;
        return state;
      })
      .addCase(deleteContactEvents.rejected, (state, action) => {
        state = action.payload as any;
        return state;
      })
      .addCase(deleteContactLabel.rejected, (state, action) => {
        state = action.payload as any;
        return state;
      })

      .addCase(addContactEvents.rejected, (state, action) => {
        state = action.payload as any;
        return state;
      })
      .addCase(addTagToContacts.rejected, (state, action) => {
        state = action.payload as any;
        return state;
      })
      .addCase(updateContactNotes.rejected, (state, action) => {
        // force note component to rerender using unchanged note value
        state.contacts[action.payload as number].notes =
          state.contacts[action.payload as number].notes + " ";

        return state;
      });
  },
});

export const {
  setContactEvents,
  setContactNote,
  setContactStar,
  setContactTags,
  setDeleteContactLoading,
  setEmailDigestId,
  removeContactEvents,
  removeContactTags,
  refreshDeleteState,
  setErrorMessage,
  setCurrentContactId
} = actionsSlice.actions;

export default actionsSlice.reducer;
