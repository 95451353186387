import { Box } from "@mui/material";
import { Transition } from "react-transition-group";
import { useLayoutEffect, useRef } from "react";
import {
  useAppSelector,
  reviewContact,
  snoozeContact,
  useAppDispatch,
  toggleAnimCount,
} from "../../../appStore";
import "../../../styles/contactgroup.css";
import { ContactType, animationStep } from "../../../utils";

let initialX: number, initialY: number;

/**
 * Mobile Transition Element
 * Important to note that the animation happens before the action (review | snooze)
 */

const TransitionElement = ({
  children,
  contact,
  onReview,
}: {
  children: React.ReactNode;
  contact: ContactType;
  onReview: () => void;
}) => {
  const dispatch = useAppDispatch();
  // current animation state
  const animState = useAppSelector((state) => state.mobileAnimation.animCount);

  // reason for the animation, can be review | snooze
  const animReason = useAppSelector(
    (state) => state.mobileAnimation.animReason
  );

  const activeCardRef = useRef<HTMLDivElement | null>(null);
  useLayoutEffect(() => {
    dispatch(toggleAnimCount([false, ""]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact.id]);

  const isSwipeMode = useRef(false);

  const handleDragEnd = (x: number, y: number, node: HTMLElement) => {
    isSwipeMode.current = false;
    node.style.transition = "transform 0.1s ease-in-out";
    //alert("ended");
    const deltaX = x - initialX;
    const deltaY = y - initialY;
    const rotateFraction = (deltaX / window.innerWidth / 1.4) * 30; //90;
    /*
    if (Math.abs(deltaY) > 30) {
      node.style.transform = "translateX(0px)";
      return;
    }
     * */
    if (deltaX < -80) {
      const transform = `rotate(${rotateFraction}deg) translate(${
        -window.innerWidth
        //20 * unitChange
      }px, ${deltaY + 30 * (deltaX / window.innerWidth)}px)`;
      node.style.transform = transform;

      setTimeout(() => {
        onReview();
        dispatch(snoozeContact(contact.id));
        node.style.opacity = "0.2";
        window.scrollTo(0, 0);
        requestAnimationFrame((ts) => {
          animationStep({
            timestamp: ts,
            limit: 600,
            speed: 0.01,
            element: activeCardRef,
            duration: 300,
            negative: true,
            startPoint: deltaX,
          });
        });
      }, 100);
      return;
    }
    if (deltaX > 80) {
      const transform = `rotate(${rotateFraction}deg) translate(${
        window.innerWidth
        //20 * unitChange
      }px, ${deltaY + 30 * (deltaX / window.innerWidth)}px)`;
      node.style.transform = transform;

      setTimeout(() => {
        onReview();
        dispatch(reviewContact(contact.id));
        node.style.opacity = "0.2";
        window.scrollTo(0, 0);
        requestAnimationFrame((ts) => {
          animationStep({
            timestamp: ts,
            limit: 600,
            speed: 0.01,
            element: activeCardRef,
            duration: 300,
            startPoint: deltaX,
          });
        });
      }, 100);
      return;
    }
    // if neither swiping left nor right, then snap back in place!
    node.style.transform = "translateX(0px)";
    // node.style.translate = "none";
  };

  const handleDrag = (x: number, y: number, node: HTMLElement) => {
    node.style.transition = "transform 0s ease-in-out";
    const deltaX = x - initialX;
    const deltaY = y - initialY;
    const rotateFraction = (deltaX / window.innerWidth / 1.4) * 30; //90;
    // const unitChange = deltaX / Math.abs(deltaX);
    /*
    if (Math.abs(deltaY) > 15) {
      node.style.transform = "translateX(0px)";
      return;
    }
     */
    if (Math.abs(deltaX) > 30 || isSwipeMode.current) {
      isSwipeMode.current = true;
      // const transform = `translate(${deltaX}px, 0px)`;
      const transform = `rotate(${rotateFraction}deg) translate(${
        deltaX
        //20 * unitChange
      }px, ${deltaY + 30 * (deltaX / window.innerWidth)}px)`;
      node.style.transform = transform;
    }
  };

  return (
    <Transition
      mountOnEnter
      in={!animState}
      timeout={0}
      unmountOnExit
      onExited={() => {
        if (animReason === "review") {
          onReview();
          dispatch(reviewContact(contact.id));
        }
        if (animReason === "snooze") {
          onReview();
          dispatch(snoozeContact(contact.id));
        }
      }}
      onExiting={(node) => {
        if (animReason === "review") {
          window.scrollTo(0, 0);
          requestAnimationFrame((ts) => {
            animationStep({
              timestamp: ts,
              limit: 600,
              speed: 0.01,
              element: activeCardRef,
              duration: 300,
              startPoint: 120,
            });
          });
        }
        if (animReason === "snooze") {
          window.scrollTo(0, 0);
          requestAnimationFrame((ts) =>
            animationStep({
              timestamp: ts,
              limit: 600,
              speed: 0.01,
              element: activeCardRef,
              duration: 300,
              negative: true,
              startPoint: -120,
            })
          );
        }
      }}
    >
      <Box
        onTouchStart={(e) => {
          const { targetTouches } = e;
          const [{ pageX, pageY }] = Array.from(targetTouches);
          initialX = pageX;
          initialY = pageY;
        }}
        draggable
        onTouchEnd={(e) => {
          const { changedTouches } = e;
          const [{ pageX, pageY }] = Array.from(changedTouches);
          handleDragEnd(pageX, pageY, activeCardRef.current as HTMLElement);
        }}
        className="action-card"
        onTouchMove={(e) => {
          const { targetTouches } = e;
          const [{ pageX, pageY }] = Array.from(targetTouches);
          handleDrag(pageX, pageY, activeCardRef.current as HTMLElement);
        }}
        style={{ opacity: 1, translate: "none", touchAction: "pan-y" }}
        ref={activeCardRef}
      >
        {children}
      </Box>
    </Transition>
  );
};

export default TransitionElement;
