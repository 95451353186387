import DeleteOutline from "@mui/icons-material/DeleteOutline";
import EditOutlined from "@mui/icons-material/EditOutlined";
import HistorySharp from "@mui/icons-material/HistorySharp";
import SellOutlined from "@mui/icons-material/SellOutlined";
import StarBorderOutlined from "@mui/icons-material/StarBorderOutlined";
import ThumbUpAltOutlined from "@mui/icons-material/ThumbUpAltOutlined";
import Star from "@mui/icons-material/Star";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper,
  Stack,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { ActionButton, SnoozeIcon, ReviewIcon } from "../../";
import {
  openDeleteContactDialog,
  reviewContact,
  scrollToTag,
  snoozeContact,
  toggleContactStarState,
  toggleTagInput,
  useAppDispatch,
  toggleAnimCount,
  setContactStar,
} from "../../../appStore";
import { ActionComponentProps } from "../../../utils";

/**
 *
 * @returns The group of actions displayed at the bottom of a single full contact componebt on desktop and at the bottom of the page on mobile
 */
const ActionButtonGroup = ({ contact, contactComponentDiv }: ActionComponentProps) => {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );

  const snooze = () => {
    dispatch(snoozeContact(contact.id));
  };

  const openTags = () => {
    dispatch(toggleTagInput(true));
    dispatch(scrollToTag());
  };

  const deleteContact = () => {
    dispatch(openDeleteContactDialog());
  };
  const review = () => {
    dispatch(reviewContact(contact.id));
    setTimeout(() => {
      contactComponentDiv?.scrollIntoView({behavior: "smooth"});
    }, 1000)
  };

  const starContact = () => {
    dispatch(setContactStar(contact.id));
    dispatch(toggleContactStarState(contact.id));
  };

  //
  return !isMobile ? (
    <Paper
      elevation={1}
      sx={{
        maxWidth: 600,
        borderRadius: "15px",
        padding: "20px 30px",
        mx: "auto",
        py: 3,
      }}
    >
      <Stack direction="row" spacing={2} justifyContent="center">
        <ActionButton
          letter="s"
          startIcon={<HistorySharp />}
          color="info"
          tooltip="Snooze Contact"
          onClick={snooze}
        />
        <ActionButton
          letter="r"
          startIcon={<DeleteOutline />}
          onClick={deleteContact}
          tooltip="Delete Contact"
        />

        <ActionButton
          letter="f"
          startIcon={
            contact.starred ? <Star htmlColor="#fff" /> : <StarBorderOutlined />
          }
          tooltip={contact.starred ? "Unstar Contact" : "Star Contact"}
          onClick={starContact}
        />

        <ActionButton
          letter="t"
          startIcon={<SellOutlined />}
          tooltip="Edit Tags"
          onClick={openTags}
        />

        <ActionButton
          letter="a"
          startIcon={<ThumbUpAltOutlined />}
          color="success"
          tooltip="Mark as reviewed and go to next"
          onClick={review}
          id="reviewBtn"
          onKeyDown={(e) => {
            if (e.key === "Tab" && !e.shiftKey) {
              e.preventDefault();
              //return tab to star icon
              let starIcon = document.querySelector("#starIcon") as HTMLElement;
              starIcon?.focus();
            }
            if (e.key === "Enter") {
              //Enter key is already set as hotkey for review
              e.preventDefault();
            }
          }}
        />
      </Stack>
    </Paper>
  ) : (
    <Box
      sx={{
        position: "fixed !important",
        bottom: 0,
        left: 0,
        width: "100vw",
        overflow: "scroll",
        boxShadow: "0px 1px 7px rgb(152 152 152 / 30%)",
      }}
    >
      <BottomNavigation sx={{ height: 70, padding: "0 1rem" }}>
        <BottomNavigationAction
          sx={{ minWidth: "unset" }}
          icon={<SnoozeIcon fontSize="large" />}
          onClick={() => {
            dispatch(toggleAnimCount([true, "snooze"]));
          }}
          onTouchEnd={() => {
            dispatch(toggleAnimCount([true, "snooze"]));
          }}
        />
        <BottomNavigationAction
          sx={{ minWidth: "unset" }}
          icon={
            <EditOutlined
              fontSize="large"
              htmlColor="#A9BCD0"
              className="default-icon-with-state"
            />
          }
          onClick={() => {
            window.open(contact.link_to_contact, "_blank");
          }}
          onTouchEnd={() => {
            window.open(contact.link_to_contact, "_blank");
          }}
        />
        <BottomNavigationAction
          sx={{ minWidth: "unset" }}
          icon={
            <DeleteOutline
              fontSize="large"
              htmlColor="#A9BCD0"
              className="default-icon-with-state"
            />
          }
          onClick={deleteContact}
          // onTouchEnd={deleteContact}
        />
        <BottomNavigationAction
          sx={{ minWidth: "unset" }}
          icon={
            <SellOutlined
              fontSize="large"
              htmlColor="#A9BCD0"
              onClick={openTags}
              onTouchEnd={openTags}
              className="default-icon-with-state"
            />
          }
        />
        <BottomNavigationAction
          sx={{ minWidth: "unset" }}
          icon={<ReviewIcon fontSize="large" />}
          onClick={() => {
            dispatch(toggleAnimCount([true, "review"]));
          }}
          onTouchEnd={() => {
            dispatch(toggleAnimCount([true, "review"]));
          }}
        />
      </BottomNavigation>
    </Box>
  );
};

export default ActionButtonGroup;
