import { useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, Typography } from "@mui/material";
import { CheckRingRound } from "../../assets/icons";
import { useAppDispatch, useAppSelector, closeDialog, refreshDeleteState } from "../../appStore";

/**
 *
 * @returns confirmation dialog for successful contact deletion
 */
const ContactDeletedDialog = () => {
  const dispatch = useAppDispatch();
  const openState = useAppSelector((state) => state.dialog.contactDeleted);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (openState) {
      timer = setTimeout(() => {
        dispatch(closeDialog());
        dispatch(refreshDeleteState());
      }, 2000);
    }
    return () => {
      clearTimeout(timer);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openState]);

  const close = () => {
    dispatch(closeDialog());
    dispatch(refreshDeleteState());
  };

  return (
    <Dialog
      open={openState}
      sx={{ textAlign: "center" }}
      onClose={close}
      maxWidth="sm"
    >
      <DialogTitle>
        <CheckRingRound width={36} height={36} />
      </DialogTitle>
      <DialogContent sx={{ px: "54px" }}>
        <Typography variant="h3" fontSize={23} fontWeight={700} mb={2}>
          Your contact has been deleted{" "}
        </Typography>
        <Typography fontSize={8} fontWeight={400} lineHeight="12px">
          Deleted contacts may be recovered for up to 30 days from the Google
          Contacts Trash
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default ContactDeletedDialog;
