import { useAppSelector } from "../../appStore";
import { ErrorLayout } from "..";

/**
 * @returns Dialog to display error when the delete contact action fails
 */
const DeleteContactError = () => {
  const openState = useAppSelector((state) => state.errors.deleteContactError);

  return (
    <ErrorLayout
      openState={openState}
      title="Can’t delete contact"
      description="Please refresh the page to try deleting the contact again"
    />
  );
};

export default DeleteContactError;
