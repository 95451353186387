import { Link } from "react-router-dom";
import { Box } from "@mui/material";

type Props = {
  /**
   * Centers the footer links in the page if true.
   * Defaults to false ( left aligned )
   */
  center?: boolean;
};

/**
 * Displays the footer links
 */
const FooterLinks = ({ center = false }: Props): JSX.Element => {
  return (
    <Box
      fontSize={12}
      // width="100vw"
      textAlign={center ? "center" : "left"}
      sx={{
        mb: "auto",
        mt: { xs: 2, sm: "5rem" },
        a: {
          textDecoration: "none",
        },
      }}
    >
      <Link to="#terms" className="light-text" style={{ marginRight: "1rem" }} tabIndex={-1}>
        Terms and Conditions
      </Link>
      <Link to="#privacy" className="light-text" tabIndex={-1}>
        Privacy Policy
      </Link>
    </Box>
  );
};

export default FooterLinks;
