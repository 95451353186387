import { Divider, Stack } from "@mui/material";
import { ActionContainer, ActionTrigger } from "../../";

/**
 * Allows the editing, updating of tags and displays them with conditional logic
 */
const NextContactPlaceholder = () => {  

  return (
    <Stack>
        <ActionContainer
            id="tag-action-form"
            title="Tags"
            trigger={
                <ActionTrigger
                    letter="T"
                    description="Add Tag"
                    tabIndex={-1}
                />
            }
            description={"Turn your contacts into an efficient network with tags!"}
        >
        </ActionContainer>
        <StyledDivider />
        <ActionContainer
            id="notes-action-form"
            title="Notes"
            trigger={
                <ActionTrigger
                    letter="N"
                    description="Add Note"
                    tabIndex={-1}
                />
            }
        >
        </ActionContainer>
        <StyledDivider />
        <ActionContainer
            id="date-action-form"
            title="Relevant Dates:"
            trigger={
                <ActionTrigger
                    letter="D"
                    description="Add Date"
                    tabIndex={-1}
                />
            }
            description={"  Turn your contacts into an efficient network with relevant dates!"}
        >
        </ActionContainer>
        <StyledDivider />
    </Stack>
  );
};

export default NextContactPlaceholder;


const StyledDivider = () => {
    return (
      <Divider
        light
        sx={{
          pt: "20px",
          mb: "20px",
        }}
      />
    );
};