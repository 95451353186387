import { forwardRef, useState } from "react";
import { SvgIconProps } from "@mui/material";
import HistorySharp from "@mui/icons-material/HistorySharp";
import WatchLater from "@mui/icons-material/WatchLater";

const Star = forwardRef<SVGSVGElement, SvgIconProps>(
  ({ ...restofProps }, ref) => {
    const [hoverState, setHoverState] = useState<boolean>(false);
    return (
      <span
        style={{
          display: "grid",
          placeItems: "center",
          fontSize: "26px",
        }}
        onMouseOver={() => {
          setHoverState(true);
        }}
        onMouseLeave={() => {
          setHoverState(false);
        }}
      >
        {hoverState ? (
          <WatchLater
            ref={ref}
            htmlColor="#F5C74B"
            tabIndex={1}
            {...restofProps}
          />
        ) : (
          <HistorySharp ref={ref} color="info" tabIndex={1} {...restofProps} />
        )}
      </span>
    );
  }
);

export default Star;
