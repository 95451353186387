import { Stack, Box, Typography, StackProps } from "@mui/material";
import { ReactNode } from "react";

type Props = StackProps & {
  title: string;
  description?: string;
  trigger: JSX.Element;
  children: ReactNode;
};

/**
 * container to give consistent styling to the 3 lower action components
 * @params title, description, trigger, children
 * @returns
 */
const ActionContainer = (props: Props) => {
  return (
    <Stack {...props}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h4" mb={2} fontSize={19}>
          {props.title}
        </Typography>

        <Box>{props.trigger}</Box>
      </Stack>

      <Box
        sx={{
          maxWidth: "570px",
        }}
      >
        <Typography
          sx={{ color: "#000000", fontSize: { xs: 12, sm: 13 }, mb: "10px" }}
        >
          {props.description}
        </Typography>
        {props.children}
      </Box>
    </Stack>
  );
};

export default ActionContainer;
