import axios from "axios";
import { closeError, setErrorMessage, signout } from "./slices";
import { store } from "./store";

export const baseURL = process.env.REACT_APP_API_BASE_URL;

const AXIOS_CLIENT = axios.create({
  baseURL,
  headers: {
    "access-token": "",
    client: "",
    uid: "",
  },
});

AXIOS_CLIENT.interceptors.request.use((config) => {
  const state = store.getState();
  if (config.headers) {
    config.headers["access-token"] = state.user.access_token;
    config.headers["client"] = state.user.client;
    config.headers["uid"] = state.user.uid;
  }
  return config;
});

AXIOS_CLIENT.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(signout());
      store.dispatch(closeError());
    }
    if (error.response.status === 422) {
      if (error.response?.data?.message) {
        if (error.response?.data?.message.includes("sign in")) {
          store.dispatch(signout());
          store.dispatch(closeError());
        } else {
          store.dispatch(setErrorMessage(error.response?.data?.message))
        }
      }
      if (error.response?.data?.errors) {
        const isAuthError = Array.isArray(error.response?.data?.errors) &&
          error.response?.data?.errors?.find((errorMsg: any) => {
            return typeof errorMsg === 'string' && errorMsg.includes("sign in")
          })
        
        if (isAuthError) {
          store.dispatch(signout());
          store.dispatch(closeError());
        }
      }
    }
  }
);

export default AXIOS_CLIENT;
