import { useAppSelector } from "../../appStore";
import { ErrorLayout } from "..";

/**
 * @returns Dialog to display error when the add contact event action fails
 */
const AddContactEventsError = () => {
  const openState = useAppSelector(
    (state) => state.errors.addContactEventsError
  );

  return (
    <ErrorLayout
      openState={openState}
      title="Can’t add contact event"
      description="Please refresh the page to try adding the event again"
    />
  );
};

export default AddContactEventsError;
