import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import { closeError, fetchActiveDigest, reviewContact, useAppDispatch, useAppSelector } from "../../appStore";
import { ResponsiveButtonGroup } from "..";
import { ReactNode } from "react";

type Props = {
  openState: boolean;
  action?: () => {};
  title: ReactNode;
  description?: ReactNode;
  button1Text?: string;
  button2Text?: string;
  reorder?: boolean;
};
/**
 * @returns Dialog to display errors
 */
const ErrorLayout = ({
  openState,
  action,
  title,
  description,
  button1Text,
  button2Text,
  reorder,
}: Props) => {
  const dispatch = useAppDispatch();
  const addTagError = useAppSelector(
    (state) => state.errors.addContactLabelError
  );
  const currentContactId = useAppSelector(
    (state) => state.actions.present.currentContactId
  );

  const handleGoToNextContact = () => {
    if (addTagError && currentContactId) {
      dispatch(reviewContact(currentContactId))
    }
  }

  const close = () => {
    handleGoToNextContact();
    dispatch(fetchActiveDigest());
    dispatch(closeError());
  };

  return (
    <Dialog
      open={openState}
      sx={{ textAlign: "center" }}
      onClose={close}
      maxWidth="xs"
    >
      <Close
        style={{
          position: "absolute",
          top: 24,
          right: 24,
          color: "#222628",
        }}
        fontSize="small"
        onClick={close}
      />
      <DialogTitle sx={{ px: "60px" }}>{title}</DialogTitle>
      <DialogContent>
        {
          description &&
          <Typography sx={{ mb: 4, px: "30px" }} fontSize={13}>
            {description}
          </Typography>
        }
        <ResponsiveButtonGroup
          button1={
            !addTagError ?
              <Button
                variant="contained"
                onClick={() => {
                  setTimeout(() => {
                    action ? action() : window.location.reload();
                    dispatch(closeError());
                  }, 2000);
                }}
                sx={{ px: { sm: "0 !important" } }}
              >
                {button1Text || "OK, refresh"}
              </Button>
            : undefined
          }
          button2={
            <Button
              variant={addTagError ? "contained" : "outlined"}
              className={addTagError ? "" : "custom-outlined"}
              onClick={close}
            >
              {button2Text || "Cancel"}
            </Button>
          }
        />
      </DialogContent>
    </Dialog>
  );
};

export default ErrorLayout;
