import { GlobalHotKeys, configure, KeyMap } from "react-hotkeys";
import {
  useAppDispatch,
  // scrollToDate,
  // scrollToNotes,
  // scrollToTag,
  toggleDateInput,
  toggleNoteInput,
  toggleTagInput,
  openDeleteContactDialog,
  toggleDateInputFocus,
  closeAllActiveInputs,
} from "../../appStore";
import { handleHotKey } from "../../utils";
import { useLayoutEffect } from "react";

const HotKeyList = () => {
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    // top level focus on "esc"
    let escape = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        dispatch(closeAllActiveInputs());
        window.focus();
      }
    };
    document.addEventListener("keyup", escape);

    return () => {
      document.removeEventListener("keyup", escape);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  configure({
    defaultKeyEvent: "keyup",
  });

  const keyMap: KeyMap = {
    OPEN_ACTION_NOTES: ["n", "N"],
    OPEN_ACTION_TAGS: ["t", "T"],
    OPEN_ACTION_DATES: ["d", "D"],
    OPEN_DELETE_CONTACT_DIALOG: ["r", "R"],
  };

  const handlers = {
    OPEN_ACTION_NOTES: handleHotKey(() => {
      // dispatch(scrollToNotes());
      dispatch(toggleNoteInput(true));
    }),
    OPEN_ACTION_TAGS: handleHotKey(() => {
      dispatch(toggleTagInput(true));
      // dispatch(scrollToTag());
    }),
    OPEN_ACTION_DATES: handleHotKey(() => {
      // dispatch(scrollToDate());
      dispatch(toggleDateInput(true));
      dispatch(toggleDateInputFocus(true));
    }),

    OPEN_DELETE_CONTACT_DIALOG: handleHotKey(() =>
      dispatch(openDeleteContactDialog())
    ),
  };

  return <GlobalHotKeys keyMap={keyMap} handlers={handlers} />;
};

export default HotKeyList;
