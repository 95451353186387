import { createSelector } from "@reduxjs/toolkit";
import { StatisticsType } from "../../../utils";
import { RootState } from "../../store";

export const selectBadgeRatio = createSelector(
  [(state: RootState) => state.statistics],
  (stats) => {
    let arr = (Object.keys(stats) as (keyof StatisticsType)[]).filter((item) =>
      item.includes("badge")
    );
    let active = 0;

    arr.forEach((item) => {
      stats[item] && active++;
    });

    return `${active}/${arr.length}`;
  }
);

/**
 * @returns boolean - true if there is a pending review
 */
export const selectPendingReviewStreak = createSelector(
  [(state: RootState) => state.statistics.review_streaks],
  (streaks) => streaks.find((item) => item.status === "pending")
);
