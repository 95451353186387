import { useRef, useState } from "react";
import { isValid, format } from "date-fns";
import {
  // ActionTrigger,
  ActionContainer
} from "../../";
import {
  // selectDateInputVisisbility,
  // toggleDateInput,
  useAppDispatch,
  useAppSelector,
  SelectContactEvents,
  addContactEvents,
  setContactEvents,
} from "../../../appStore";
import { ActionComponentProps, EventInputType } from "../../../utils";
import DateForm from "./DateForm";
import DateList from "./DateList";

const initialCurrentDate: EventInputType = {
  label: "",
  event_time: format(new Date(), "yyyy-MM-dd"),
};

/**
 * Allows the editing, updating of dates and events and displays them with conditional logic
 */
const DateAction = ({ contact }: ActionComponentProps) => {
  const dispatch = useAppDispatch();
  // const showInput = useAppSelector(selectDateInputVisisbility);
  const [currentDate, setCurrentDate] = useState(initialCurrentDate);
  const dateState =
    useAppSelector((state) => SelectContactEvents(state, contact.id)) || [];
  const dateInputRef = useRef<HTMLDivElement | null> (null);
  const totalReviewed = useAppSelector((state) => state.statistics.contacts_reviewed);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target as HTMLInputElement;
    setCurrentDate((prev) => ({ ...prev, [name]: value }));
  };
  // Add a new date to component date state and check that the date is valid

  // useEffect(() => {
  //   if (showInput) {
  //     dateInputRef.current && dateInputRef.current.scrollIntoView();
  //   }
  // }, [showInput])

  const addDate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const parsed = new Date(currentDate.event_time);
    if (isValid(parsed))
      // update redux state
      currentDate.label &&
        dispatch(
          setContactEvents({
            id: contact.id,
            event: { ...currentDate, id: -100 },
          })
        );
    // send API request to add event
    dispatch(addContactEvents({ id: contact.id, event: currentDate }));
    isValid(parsed) && setCurrentDate(initialCurrentDate);
  };

  return (
    <ActionContainer
      id="date-action-form"
      title="Relevant Dates:"
      trigger={
        <></>
        // <ActionTrigger
        //   letter="D"
        //   description="Add date"
        //   onClick={() => {
        //     dispatch(toggleDateInput());
        //   }}
        // />
      }
      // only show description if there are no dates
      description={
        // !dateState.length
        totalReviewed
          ? ""
          : "  Turn your contacts into an efficient network with relevant dates!"
      }
    >
      {
        // show the date form
        // showInput ? (
          <div
            ref={dateInputRef}
          >
            <DateForm
              onSubmit={addDate}
              onChange={onChange}
              data={currentDate}
              show={true}
            />
          </div>
        // ) : null
      }

      {
        // show date list
        dateState.length ? (
          <DateList data={dateState} contactId={contact.id} />
        ) : null
      }

      {/*  */}
    </ActionContainer>
  );
};

export default DateAction;
