import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
  closeDialog,
  openManagePaymentMethodDialog,
} from "../../appStore";

const UpgradePlanDialog = () => {
  const dispatch = useAppDispatch();
  const openState = useAppSelector((state) => state.dialog.upgradePlan);
  const close = () => {
    dispatch(closeDialog());
  };

  return (
    <Dialog
      open={openState}
      sx={{ textAlign: "center" }}
      onClose={close}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          dispatch(openManagePaymentMethodDialog());
        }
      }}
    >
      <DialogTitle
        sx={{
          mx: 2,
          mb: 1,
          fontSize: { xs: "19px", sm: "23px" },
          fontWeight: 600,
        }}
      >
        Want to pick how many contacts and how frequently you get them?
      </DialogTitle>
      <DialogContent sx={{ px: "84px" }}>
        <Button
          fullWidth
          variant="contained"
          sx={{ mb: 3 }}
          onClick={() => dispatch(openManagePaymentMethodDialog())}
        >
          Review Your Contacts Without Limits
        </Button>
        <Typography fontSize={10}>
          Recontact Premium is an annual subscription at $9/year
        </Typography>
        <Typography fontSize={13} px={3}>
          <Link to="submit-contacts" onClick={() => dispatch(closeDialog())}>
            continue and review my 5 contacts every month for free
          </Link>
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default UpgradePlanDialog;
