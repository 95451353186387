import {
  Grid,
  Typography,
  Divider,
  useMediaQuery,
  Theme,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { LightTooltip } from "../../";
import setupTooltip from "../../../assets/images/setup-tooltip.png";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import {
  selectSettingState,
  useAppSelector,
  setHowToSaveTags,
  useAppDispatch,
} from "../../../appStore";

/**
 * Displays the tag settings Component
 *
 * Allowing users to choose how to save tags
 */
const TagSettings = () => {
  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("md")
  );
  const tagSyncValue = useAppSelector(selectSettingState).sync_labels;
  const dispatch = useAppDispatch();
  return (
    <Grid item md={6} xs={12}>
      {isMobile ? <Divider sx={{ my: 3 }} light /> : null}
      <Typography variant="h5" mb={1}>
        How should we save tags?{" "}
        <LightTooltip
          title={<img style={{ width: isMobile ? 150 : '' }} src={setupTooltip} alt="" />}
          sx={{
            maxWidth: "250px",
            img: {
              width: { xs: "auto", md: "200px" },
            },
          }}
          arrow
        >
          <InfoOutlined sx={{ mb: -0.8 }} htmlColor="#363636" style={{ cursor: 'pointer' }} />
        </LightTooltip>
      </Typography>

      <RadioGroup
        value={tagSyncValue}
        onChange={(e) => {
          dispatch(setHowToSaveTags(e.target.value === "true" ? true : false));
        }}
      >
        <FormControlLabel
          value={true}
          control={<Radio />}
          label="As a Google Contacts label"
        />
        <FormControlLabel
          value={false}
          control={<Radio />}
          label="As a custom field"
        />
      </RadioGroup>
    </Grid>
  );
};

export default TagSettings;
