import { Grid, Typography, Stack, Avatar, Box } from "@mui/material";
import { MonthlyPlanCard, SignOut, YearlyPlanCard } from "../..";
import { useAppSelector, selectUserData } from "../../../appStore";

const SettingsBottom = () => {
  const userData = useAppSelector(selectUserData);
  const activePlan = useAppSelector((state) =>
    state.payment.plan_details
  );
  // const isPremium = userData.subscribed;
  return (
    <Grid container spacing={{ md: 2, lg: 6 }}>
      <Grid item md={6}>
        <Stack
          direction="row"
          justifyContent="space-between"
          mb={{ xs: 2, lg: 4 }}
          sx={{ width: "100%" }}
        >
          <Typography fontWeight={600}>Subscription status:</Typography>
          <Typography fontWeight={600} color="primary">
            {/* to be changed when stripe is integrated */}
            {activePlan?.interval === "month" ? "Monthly" : "Yearly"}
          </Typography>
        </Stack>
        {activePlan?.interval === "month" ? (
          <MonthlyPlanCard md={12} mx={0} />
        ) : (
          <YearlyPlanCard md={12} mx={0} />
        )}
      </Grid>

      {/*  */}

      <Grid item md={6} xs={12} mt={{ xs: 4, md: 0 }}>
        <Box ml={{ md: 2.5 }}>
          <Typography fontWeight={600} mb={{ xs: 2, lg: 4 }}>
            Connected account
          </Typography>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Avatar
                src={userData.image_url}
                imgProps={{
                  referrerPolicy: "no-referrer",
                }}
              />
              <Typography sx={{ color: "#363636" }}>
                {userData.email}
              </Typography>
            </Stack>
            <SignOut />
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SettingsBottom;
