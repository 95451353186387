import { InfoCard, IconText } from "../..";
import { CheckRingRound } from "../../../assets/icons";
import { Box, Button, GridProps, Typography, useMediaQuery, Theme } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
  initializePayment,
  managePayment,
  selectIsSubscribed,
} from "../../../appStore";

/**
 *
 * @returns the monthly plan card - JSX element
 */
const MonthlyPlanCard = ({ ...props }: GridProps) => {
  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );

  const dispatch = useAppDispatch();
  const isSubscribed = useAppSelector(selectIsSubscribed);
  const activePlan = useAppSelector((state) =>
    state.payment.plan_details
  );
  const plan = useAppSelector((state) =>
    state.payment.plans.find((plan) => plan.interval === "month")
  );
  // const isMonthlyPlan = useAppSelector(selectUserPlanId) === "monthly";
  const isMonthlyPlan = activePlan?.id === plan?.id;
  const payment = useAppSelector((state) =>
    state.payment
  );

  return (
    <InfoCard
      header="Monthly"
      highlight={`${payment.trial_active ? 'Free 14 days, then' : ''} ${parseFloat(plan?.amount || "0")}$/mo`}
      sx={{ width: { md: "548px", xl: "657px" } }}
      stackDirection={isMobile && payment.trial_active ? 'column' : 'row'}
      // allow custom sizing
      {...props}
    >
      <Box
        mb={4}
        sx={{
          "> *": {
            mb: 2,
          },
        }}
      >
        <IconText
          icon={<CheckRingRound />}
          text="Review your contacts easily through a daily email and app"
        />
        <IconText
          icon={<CheckRingRound />}
          text="Add tags, notes, and dates to organize and connect your contacts in new ways"
        />
        <IconText
          icon={<CheckRingRound />}
          text="We'll sync your updates automatically and continuously right to Google Contacts"
        />
        <IconText
          icon={<CheckRingRound />}
          text={`Only $${(parseFloat(plan?.amount || "0") / 4).toFixed(2)} a week`}
        />
        <IconText icon={<CheckRingRound />} text="Billed every month" />
        <IconText icon={<CheckRingRound />} text="Cancel anytime" />
      </Box>
      <Button
        variant="contained"
        fullWidth
        sx={{ mb: { xs: "22px", sm: 1 } }}
        onClick={() => {
          isSubscribed
            ? dispatch(managePayment())
            : dispatch(initializePayment(plan?.id || "monthly"));
        }}
      >
        {isSubscribed ? "Manage payment method" : "Start trial"}
      </Button>
      <Typography
        color="primary"
        textAlign="center"
        fontSize={13}
        fontWeight={400}
        sx={{
          cursor: isMonthlyPlan ? "pointer" : "text",
        }}
        onClick={() => {
          isMonthlyPlan && dispatch(managePayment());
        }}
      >
        {isMonthlyPlan
          ? "Change to yearly"
          : `Start ${plan?.trial || ""} free trial, cancel anytime`}
      </Typography>
    </InfoCard>
  );
};

export default MonthlyPlanCard;
