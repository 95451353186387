import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { scroller } from "react-scroll";
import { ActionInputsControls, falsifyObject } from "../../../utils";
import { RootState } from "../../store";

/**
 * @todo move all formerly local state for these inputs to this slice
 * @todo create an action that clears the content of these  inputs - to be dispatched after successful snooze or review
 */

const initialState: ActionInputsControls = {
  tagInputVisibility: false,
  dateInputVisibility: false,
  noteInputVisibility: false,
  dateInputFocus: false,
};

/*******************THUNKS************************/

/**
 * Action Inputs controls
 */
const actionInputSlice = createSlice({
  name: "actionInputSlice",
  initialState,
  reducers: {
    toggleTagInput: (state, action: PayloadAction<boolean | undefined>) => {
      state.tagInputVisibility = action?.payload || !state.tagInputVisibility;
    },
    scrollToTag: () => {
      scroller.scrollTo("tag-action-form", { smooth: true });
    },
    toggleDateInput: (state, action: PayloadAction<boolean | undefined>) => {
      state.dateInputVisibility = action?.payload || !state.dateInputVisibility;

      if (state.dateInputVisibility) {
        state.dateInputFocus = true;
      } else {
        state.dateInputFocus = false;
      }
    },
    toggleDateInputFocus: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.dateInputFocus = action?.payload || !state.dateInputFocus;
    },
    scrollToDate: () => {
      scroller.scrollTo("date-action-form", { smooth: true });
    },
    toggleNoteInput: (state, action: PayloadAction<boolean | undefined>) => {
      state.noteInputVisibility = action?.payload || !state.noteInputVisibility;
    },
    scrollToNotes: () => {
      scroller.scrollTo("notes-action-form", { smooth: true });
    },
    closeAllActiveInputs: (state) => {
      state = falsifyObject(state);
      return state;
    },
  },
});

/*******************SELECTORS************************/

/**
 * Select the tag input visibility
 */
export const selectTagInputVisisbility = createSelector(
  [(state: RootState) => state.actionInput.tagInputVisibility],
  (visibility) => visibility
);

export const selectDateInputVisisbility = createSelector(
  [(state: RootState) => state.actionInput.dateInputVisibility],
  (visibility) => visibility
);

export const selectNotesInputVisisbility = createSelector(
  [(state: RootState) => state.actionInput.noteInputVisibility],
  (visibility) => visibility
);

export const {
  toggleTagInput,
  toggleDateInput,
  toggleNoteInput,
  toggleDateInputFocus,
  scrollToDate,
  scrollToNotes,
  scrollToTag,
  closeAllActiveInputs,
} = actionInputSlice.actions;

export default actionInputSlice.reducer;
