import { Box } from "@mui/material";
import Check from "@mui/icons-material/Check";
import MoreHoriz from "@mui/icons-material/MoreHoriz";

const SingleDigest = (props: {
  status: "missed" | "reviewed" | "partially_reviewed" | "pending";
}) => {
  const color =
    props.status === "missed"
      ? "error.main"
      : props.status === "partially_reviewed"
      ? "info.main"
      : props.status === "pending"
      ? "#A9BCD0"
      : props.status === "reviewed"
      ? "success.main"
      : "#AFAFAF";
  return (
    <Box>
      <Box
        sx={{
          width: { xs: 28, sm: 32 },
          height: { xs: 28, sm: 32 },
          borderRadius: "4px",
          display: "grid",
          placeItems: "center",
          bgcolor: color,
        }}
      >
        {props.status ? (
          <Check fontSize="medium" htmlColor="white" />
        ) : (
          <MoreHoriz fontSize="medium" htmlColor="white" />
        )}
      </Box>
    </Box>
  );
};

export default SingleDigest;
