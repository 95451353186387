import { ReactNode } from "react";
import { Box, Stack } from "@mui/material";
import { Logo, FooterLinks } from "..";
import NavMenu from "./NavMenu";

type Props = {
  /**
   * Elements to be displayed within the layout
   */
  children: ReactNode;
  /**
   * boolean property that determines if the navigation menu is shown or not
   * @default false
   */
  nav?: boolean;
  /**
   * boolean property that determines if the component has its own footer
   * @default false
   */
  hasOwnFooter?: boolean;
};

/**
 * Displays a simple layout with initial padding, the blue logo at top left and the footer links at the bottom center
 * @params nav : boolean , optional - displays the nav at the top right if true
 */
const Layout = ({ children, nav = false, hasOwnFooter = false }: Props) => {
  return (
    <Box
      // padding={{ xs: "1rem", md: "2.5rem 5rem", lg: "2.5rem 10rem" }}
      my={{ xs: "1rem", md: "2.5rem" }}
      sx={{
        width: { xs: "100vw", lg: 1120, xl: 1320 },
        position: "relative",
      }}
      mx="auto"
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mx: { xs: "24px", sm: "1rem", lg: "inherit" } }}
        fontSize={{ xs: 15, sm: 16 }}
      >
        <Logo />
        {
          // displays the nav component if the screen has a nav component

          nav ? <NavMenu /> : null
        }
      </Stack>
      {children}
      {
        !hasOwnFooter &&
        <Box sx={{ mb: "30px", mt: "auto" }}>
          <FooterLinks center />
        </Box>
      }
    </Box>
  );
};

export default Layout;
